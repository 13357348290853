import React, { useState, useEffect, useRef } from "react";
import detailsClasses from "./details.module.css";
import { NavLink } from "react-router-dom";
import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import Layout from "../../../Components/Layout";
import { update_batchDuration } from "../../../Services";
import { MdDelete, MdEdit } from "react-icons/md";

import { formatNumberToIndian } from "Util/Util";
import Mainloader from "../../../Components/loader/loader";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import swal from "sweetalert";
import { Error_Dark, Success_Dark, SuccessSwal } from "../../../Util/Toast";
import { AdminCancelType } from "../../../Statusconfig";
import Skeleton from "react-loading-skeleton";
import Reschedule from "./reschedule/reschedule";
import { AccessKeys } from "../../../Routes/config";
import { AccessControl } from "../../../Util/AccessControl";
import Logs from "Components/ActivityLog/log";
import CancelClass from "./CancelClass/cancel";
import moment from "moment";

import { FaEdit } from "react-icons/fa";
import CompleteModal from "./Completeclass/complete";
import AddStudent from "./addstudents/add";
function Details() {
    const Navigate = useNavigate();
    const inputref = useRef();
    const [params] = useSearchParams();
    const [rescheduleModal, setRescheduleModal] = useState(false);
    const [CancelModal, setCancelModal] = useState(false);
    const [editclass, setEditClass] = useState(false);
    const [completeClass, setCompleteClass] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [completeData, setCompleteData] = useState(null);
    const [AttendanceLoad, setAttendenceLoad] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [Tab, setTab] = useState("Active");
    const [ActiveStudents, setActiveStudents] = useState([]);

    const [expandedRows, setExpandedRows] = useState([]);
    const adminData = JSON.parse(localStorage.getItem("admindata"));
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    function getscheduledClasses() {
        Services.getDemoDetails("GET", null, token, params.get("id"))
            .then((response) => {
                if (response.Status === 1) {
                    setData({ ...response.Batches });
                    setActiveStudents(response?.Batches?.ActiveStudents);

                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                alert("something went wrong try again");
            });
    }
    useEffect(() => {
        getscheduledClasses();
        // eslint-disable-next-line
    }, [params.get("scheduleID")]);
    const loginData = JSON.parse(localStorage.getItem("admindata"));
    async function cancelClassfunc(formdata) {
        let date = new Date(data.Scheduled_StartDate);
        let TutorRms = [
            data.SecondShift_RM_Morning,
            data.SecondShift_RM_Evening,
            data.Lead_RM,
            data.Buddy_RM,
        ].filter((item) => item != 0);
        let body = JSON.stringify({
            ...formdata,

            BatchSize: data.BatchSize,
            CancelledByID: adminData?.AdminUserID,
            CancelledBy: AdminCancelType,
            Students: data?.Students,
            TutorEmailID: data?.TutorEmailID,
        });

        try {
            const cancel = await Services.CancelDemoClass(
                "PUT",
                body,
                token,
                params.get("id")
            );
            setCancelModal(false);
            if (cancel?.Status == 1) {
                swal("Class Cancelled", "", "success");
                setLoading(false);
                getscheduledClasses();
            }
            if (cancel?.Status == 0) {
                swal(cancel.Message, "", "warning");
            }
        } catch (err) {
            setCancelModal(false);
            Error_Dark("something went wrong try again");
            console.log(err);
        }
    }
    const cancelHandler = (e) => {
        setCancelModal(true);
    };
    const backButton = () => {
        Navigate(-1);
    };
    const NavHandler = (e) => {
        let type = e.currentTarget.getAttribute("btn-type");
        setTab(type);
        if (type === "Active") {
            setActiveStudents(data.ActiveStudents);
        } else {
            setActiveStudents(data.InActiveStudents);
        }
    };
    const DeleteHandler = (id, DemoRequestID) => {
        swal("Are you Sure You Want to Remove Student From Class?", {
            icon: "warning",
            // className: detailsClass["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Confirm",
                    value: "Logout",
                    className: detailsClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    let body = {
                        DemoStudentID: id,
                        DemoRequestID: DemoRequestID,
                    };
                    Services.removeDemoStudent(
                        "POST",
                        JSON.stringify(body),
                        token
                    )
                        .then((response) => {
                            if (response.Status === 1) {
                                getscheduledClasses();
                            }
                        })
                        .catch((err) => {
                            setLoading(false);
                            console.log(err);
                            alert("something went wrong try again");
                        });
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    function getTimeDifferenceInMinutes(
        ActualStartDate,
        ActualStartTime,
        ActualEndDate,
        ActualEndTime
    ) {
        // Combine date and time into Date objects

        let start = ActualStartDate.split("T");
        let END = ActualEndDate.split("T");
        console.log(start, END);
        const startDateTime = new Date(`${start[0]}T${ActualStartTime}Z`);
        const endDateTime = new Date(`${END[0]}T${ActualEndTime}Z`);

        // Calculate the difference in milliseconds
        const timeDifferenceMs = endDateTime - startDateTime;

        // Convert milliseconds to minutes
        const minutes = Math.floor(timeDifferenceMs / (1000 * 60));

        return minutes;
    }
    return (
        <>
            {AttendanceLoad && <Mainloader />}

            {completeClass && (
                <CompleteModal
                    completeData={completeData}
                    setCompleteData={setCompleteData}
                    modal={completeClass}
                    setModal={setCompleteClass}
                    getDetails={{
                        details: getscheduledClasses,
                        setLoading: setLoading,
                        data: data,
                    }}
                    id={params.get("id")}
                />
            )}
            <CancelClass
                completemodal={CancelModal}
                setModal={setCancelModal}
                data={data}
                cancelClass={cancelClassfunc}
            />

            {rescheduleModal && (
                <Reschedule
                    setRescheduleModal={setRescheduleModal}
                    rescheduleModal={rescheduleModal}
                    data={{
                        Scheduled_StartDate: data?.Scheduled_StartDate,
                        id: params.get("scheduleID"),
                        Students: data?.Students,
                        Duration: data?.Duration,
                        ClassDuration: data?.ClassDuration,
                        CourseName: data?.CourseName,
                        ScheduleCreatedBy: data.ScheduleCreatedBy,
                        details: getscheduledClasses,
                        BatchID: data?.BatchID,
                        BatchName: data?.BatchName,
                        ZoomEmailID: data?.ZoomEmailID,
                        TutorID: data?.TutorID,
                        ZoomMeetingID: data?.ZoomMeetingID,
                        ScheduleClassID: data?.ScheduleClassID,
                        ScheduleCreatedByType: data?.ScheduleCreatedByType,
                    }}
                />
            )}
            {addModal && (
                <AddStudent
                    addModal={addModal}
                    setAddModal={setAddModal}
                    BatchSize={data?.BatchSize}
                    CourseID={data?.CourseID}
                    data={data}
                    getscheduledClasses={getscheduledClasses}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Class Details</title>
            </Helmet>
            <Layout Active={"Demo Classes"}>
                {loading ? (
                    <Mainloader />
                ) : (
                    <div className={detailsClasses["container"]}>
                        <button
                            onClick={backButton}
                            className={detailsClasses["back"]}>
                            Back
                        </button>
                        <div className={detailsClasses["wrapper"]}>
                            <div className={detailsClasses["header-wrapper"]}>
                                <div className={detailsClasses["header"]}>
                                    <h3>
                                        <span></span>
                                        {data.DemoClassName}
                                    </h3>
                                    {data.DemoClassStatus == 1 &&
                                        AccessControl(
                                            AccessKeys["Demo Classes"]
                                        )?.edit === 1 && (
                                            <button
                                                type="0"
                                                onClick={cancelHandler}
                                                disabled={
                                                    data.DemoClassStatus == 2
                                                }>
                                                cancel
                                            </button>
                                        )}
                                    {/*  {AccessControl(AccessKeys["Demo Classes"])
                                        ?.edit === 1 &&
                                        data?.DemoClassStatus == 1 && (
                                            <button
                                                className={
                                                    detailsClasses["reschedule"]
                                                }
                                                onClick={() =>
                                                    setCompleteClass(true)
                                                }
                                                disabled={moment(
                                                    data?.Scheduled_StartDate
                                                ).isAfter(moment(), "day")}>
                                                Complete Class
                                            </button>
                                        )} */}

                                    {/* {data.DemoClassStatus == 1 &&
                                        AccessControl(
                                            AccessKeys["Demo Classes"]
                                        )?.edit === 1 && (
                                            <button
                                                className={
                                                    detailsClasses["reschedule"]
                                                }
                                                onClick={() =>
                                                    setRescheduleModal(true)
                                                }
                                                disabled={
                                                    data.DemoClassStatus == 2
                                                }>
                                                Reschedule
                                            </button>
                                        )} */}
                                </div>

                                {data?.DemoClassStatus == 1 && (
                                    <p className={detailsClasses["requested"]}>
                                        Pending
                                    </p>
                                )}

                                {data?.DemoClassStatus == 3 && (
                                    <p className={detailsClasses["rejected"]}>
                                        Cancelled
                                    </p>
                                )}

                                {data.DemoClassStatus == 2 && (
                                    <p
                                        className={
                                            detailsClasses["rescheduled"]
                                        }>
                                        Completed{" "}
                                    </p>
                                )}
                            </div>
                            <div className={detailsClasses["profile"]}>
                                <div className={detailsClasses["internal"]}>
                                    <div>
                                        <h3>Batch Name</h3>
                                        <p>
                                            <NavLink
                                                to={`/Batches/Details?BatchId=${data.BatchID}`}>
                                                {data.BatchName}
                                            </NavLink>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Course</h3>
                                        <p>{data.CourseName}</p>
                                    </div>
                                    <div>
                                        <h3>Batch Size</h3>
                                        <p>{data.BatchSize}</p>
                                    </div>
                                    {data.DemoClassStatus == 1 && (
                                        <div>
                                            <h3>Meeting Join Url</h3>
                                            <p>
                                                {data.MagicLink !== "NA" ? (
                                                    <a
                                                        href={data.MagicLink}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        Link
                                                    </a>
                                                ) : (
                                                    "NA"
                                                )}
                                                <button
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(
                                                            data.MagicLink
                                                        );
                                                        Success_Dark(
                                                            "Copied to Clipboard"
                                                        );
                                                    }}>
                                                    Copy
                                                </button>
                                            </p>
                                        </div>
                                    )}
                                    <div>
                                        <h3>Start Date:</h3>
                                        <p>
                                            <Moment format="DD-MM-YYYY">
                                                {new Date(data.DemoStartDate)}
                                            </Moment>
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Start Time:</h3>
                                        <p>
                                            {/* {UTC_TO_IST_TIME_CONVERSION(
                                                data.Scheduled_StartTime
                                            )} */}
                                            <Moment format="hh:mm a">
                                                {moment().set({
                                                    hours: String(
                                                        data?.DemoStartTime
                                                    ).split(":")[0],
                                                    minutes: String(
                                                        data?.DemoStartTime
                                                    ).split(":")[1],
                                                })}
                                            </Moment>
                                        </p>
                                    </div>
                                    {data?.ActualStartDate != "0000-00-00" &&
                                        data?.ActualStartTime != "00:00:00" && (
                                            <div>
                                                <h3>Actual Start Date Time:</h3>
                                                <p>
                                                    <Moment
                                                        format="DD-MM-YYYY hh:mm a "
                                                        utc>
                                                        {
                                                            new Date(
                                                                `${
                                                                    data?.ActualStartDate.split(
                                                                        "T"
                                                                    )[0]
                                                                }T${
                                                                    data?.ActualStartTime
                                                                }Z`
                                                            )
                                                        }
                                                    </Moment>
                                                </p>
                                            </div>
                                        )}
                                    {data?.ActualEndDate != "0000-00-00" &&
                                        data?.ActualEndTime != "00:00:00" && (
                                            <div>
                                                <h3>Actual End Date Time:</h3>
                                                <p>
                                                    <Moment
                                                        format="DD-MM-YYYY hh:mm a "
                                                        utc>
                                                        {
                                                            new Date(
                                                                `${
                                                                    data?.ActualEndDate.split(
                                                                        "T"
                                                                    )[0]
                                                                }T${
                                                                    data?.ActualEndTime
                                                                }Z`
                                                            )
                                                        }
                                                    </Moment>
                                                </p>
                                            </div>
                                        )}
                                    {data?.ActualStartDate != "0000-00-00" &&
                                        data?.ActualStartTime != "00:00:00" &&
                                        data?.ActualEndDate != "0000-00-00" &&
                                        data?.ActualEndTime != "00:00:00" && (
                                            <div>
                                                <h3>Actual Duration:</h3>
                                                <p>
                                                    {/* {UTC_TO_IST_TIME_CONVERSION(
                                                data.Scheduled_StartTime
                                                )} */}
                                                    {getTimeDifferenceInMinutes(
                                                        data?.ActualStartDate,
                                                        data?.ActualStartTime,
                                                        data?.ActualEndDate,
                                                        data?.ActualEndTime
                                                    )}{" "}
                                                    Min
                                                </p>
                                            </div>
                                        )}

                                    {data.DemoClassStatus == 1 && (
                                        <div>
                                            <h3>Start Url:</h3>
                                            <p>
                                                {/* {UTC_TO_IST_TIME_CONVERSION(
                                                data.Scheduled_StartTime
                                            )} */}
                                                {data?.StartURL &&
                                                data?.StartURL !== "NA" &&
                                                data.StartURL?.length > 0 ? (
                                                    <a
                                                        href={data?.StartURL}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        Link
                                                    </a>
                                                ) : (
                                                    "NA"
                                                )}
                                            </p>
                                        </div>
                                    )}
                                    {/*  */}
                                </div>
                                <div
                                    className={detailsClasses["internal"]}
                                    style={{
                                        borderLeft: "1px solid #ccc",
                                        paddingLeft: "0.5rem",
                                    }}>
                                    <h3></h3>
                                    <div>
                                        <h3>Tutor</h3>

                                        <p>
                                            <NavLink
                                                to={`/Tutor/Details?TutorId=${data.TutorID}`}>
                                                {data?.TutorName}
                                            </NavLink>
                                        </p>
                                    </div>
                                    {adminData?.Type == 14 &&
                                    data.DemoClassStatus == 2 ? (
                                        ""
                                    ) : (
                                        <div>
                                            <h3>Tutor Mail</h3>
                                            <h3>{data.TutorEmailID}</h3>
                                        </div>
                                    )}
                                    {adminData?.Type == 14 &&
                                    data.DemoClassStatus == 2 ? (
                                        ""
                                    ) : (
                                        <div>
                                            <h3>Tutor Mobile</h3>
                                            <h3>{data.MobileNumber1}</h3>
                                        </div>
                                    )}

                                    <div>
                                        <h3>Schedule Created By</h3>
                                        <p>
                                            {" "}
                                            <NavLink
                                                to={`/Administrators/details?ID=${data.ScheduleCreatedBy}`}>
                                                {data?.CreatedBy}
                                            </NavLink>
                                        </p>
                                    </div>
                                    {/*  {data?.StartURL != "NA" && (
                                        <div>
                                            <h3>Actual Start Date:</h3>
                                            <p>
                                                <Moment format="DD-MM-YYYY">
                                                    {
                                                        new Date(
                                                            data?.ActualStartDate
                                                        )
                                                    }
                                                </Moment>
                                            </p>
                                        </div>
                                    )} */}
                                    {/*  {data?.StartURL != "NA" && (
                                        <div>
                                            <h3>Actual Start Time:</h3>
                                            <p>
                                                {" "}
                                                <Moment format="hh:mm a">
                                                    {moment().set({
                                                        hours: Number(
                                                            String(
                                                                data?.ActualStartTime
                                                            ).split(":")[0]
                                                        ),
                                                        minutes: Number(
                                                            String(
                                                                data?.ActualStartTime
                                                            ).split(":")[1]
                                                        ),
                                                    })}
                                                </Moment>
                                            </p>
                                        </div>
                                    )} */}
                                    {data.DemoClassStatus == 2 && (
                                        <div>
                                            <h3>Comments</h3>
                                            <p>
                                                {" "}
                                                {data.ActionComments}
                                                {data?.DemoClassStatus == 2 && (
                                                    <button
                                                        onClick={() => {
                                                            setCompleteClass(
                                                                true
                                                            );
                                                            setCompleteData(
                                                                data.ActionComments
                                                            );
                                                        }}>
                                                        <FaEdit />
                                                    </button>
                                                )}
                                            </p>
                                        </div>
                                    )}

                                    {data.DemoClassStatus == 3 && (
                                        <div>
                                            <h3>Cancel Reason</h3>
                                            <p>{data?.ActionComments}</p>
                                        </div>
                                    )}
                                    {data.DemoClassStatus == 3 && (
                                        <div>
                                            <h3>Cancelled ON</h3>
                                            <p>
                                                {" "}
                                                <Moment format="hh:mm a Do MMM YYYY ">
                                                    {data.CancelledOn}
                                                </Moment>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={detailsClasses["Table"]}>
                                <h3>Meeting details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Download URL</th>
                                            <th>Play URL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.ZoomRestartData &&
                                        data?.ZoomRestartData.length > 0 ? (
                                            data?.ZoomRestartData.map(
                                                (item) => (
                                                    <tr>
                                                        <td>
                                                            {item?.DownloadUrl !=
                                                                "NA" &&
                                                            item.item
                                                                ?.DownloadUrl !=
                                                                "" ? (
                                                                <a
                                                                    target="_blank"
                                                                    href={
                                                                        item?.DownloadUrl
                                                                    }>
                                                                    Download
                                                                </a>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </td>
                                                        <td>
                                                            {item?.PlayURL !=
                                                                "NA" &&
                                                            item?.PlayURL !=
                                                                "" ? (
                                                                <a
                                                                    target="_blank"
                                                                    href={
                                                                        item?.PlayURL
                                                                    }>
                                                                    Play
                                                                </a>
                                                            ) : (
                                                                "NA"
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan={2}>
                                                    No Record Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>{" "}
                            <div className={detailsClasses["Table"]}>
                                {(data.DemoClassStatus == 1 ||
                                    data?.DemoClassStatus == 4) && (
                                    <div
                                        className={
                                            detailsClasses["student-select"]
                                        }>
                                        <h3>Student Details</h3>
                                        <button
                                            onClick={() => setAddModal(true)}>
                                            Add students
                                        </button>
                                    </div>
                                )}
                                <div className={detailsClasses["Navigator"]}>
                                    <button
                                        btn-type="Active"
                                        className={
                                            Tab === "Active"
                                                ? detailsClasses["active"]
                                                : ""
                                        }
                                        onClick={NavHandler}>
                                        Active
                                    </button>
                                    <button
                                        btn-type="Inactive"
                                        className={
                                            Tab === "Inactive"
                                                ? detailsClasses["active"]
                                                : ""
                                        }
                                        onClick={NavHandler}>
                                        Inactive
                                    </button>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>Parent Name</th>
                                            {adminData?.Type != 14 && (
                                                <th>Mobile Number</th>
                                            )}
                                            {/* <th>Created By</th> */}
                                            <th>Lead Owner</th>
                                            <th>Attendance</th>
                                            {/* {data?.BatchSize == "Group" && (
                                            )} */}
                                            {Tab != "Inactive" && (
                                                <th>Actions</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ActiveStudents &&
                                        ActiveStudents?.length > 0 ? (
                                            ActiveStudents?.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {item.StudentName}
                                                        </td>
                                                        <td>
                                                            {item.ParentName}
                                                        </td>
                                                        {adminData?.Role !=
                                                            41 && (
                                                            <td>
                                                                {
                                                                    item?.MobileNumber
                                                                }
                                                            </td>
                                                        )}
                                                        <td>
                                                            {item?.RequestCreatedBy_Type ==
                                                            4
                                                                ? "Parent"
                                                                : item?.StudentCreatedBy_Name}
                                                        </td>
                                                        <td>
                                                            {item?.Attendance ==
                                                            0
                                                                ? "NA"
                                                                : item?.Attendance ==
                                                                  1
                                                                ? "Present"
                                                                : "Absent"}
                                                        </td>
                                                        {/*  <td>
                                                            {item?.ClaimedByName
                                                                ? item?.ClaimedByName
                                                                : ""}
                                                        </td> */}
                                                        {data?.DemoClassStatus !=
                                                            2 &&
                                                            data?.DemoClassStatus !=
                                                                3 &&
                                                            Tab !=
                                                                "Inactive" && (
                                                                <td>
                                                                    <button
                                                                        style={{
                                                                            border: "none",
                                                                            backgroundColor:
                                                                                "inherit",
                                                                        }}
                                                                        onClick={() =>
                                                                            DeleteHandler(
                                                                                item?.DemoStudentID,
                                                                                item?.DemoRequestID
                                                                            )
                                                                        }>
                                                                        <MdDelete
                                                                            color="red"
                                                                            size={
                                                                                18
                                                                            }
                                                                        />
                                                                    </button>
                                                                </td>
                                                            )}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        data?.BatchSize ==
                                                        "Group"
                                                            ? 4
                                                            : 3
                                                    }>
                                                    No Student Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </Layout>
        </>
    );
}

export default Details;
