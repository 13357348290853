import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../../../Components/Layout";
import Mainloader from "../../../../Components/loader/loader";
import { Preferred_Time } from "../../../enrolled/enrolled/filter/helper";
import AddClasses from "./add.module.css";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { Services } from "../../../../Services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Country } from "country-state-city";
import ReactFlagsSelect from "react-flags-select";
import { warningSwal, SuccessSwal } from "../../../../Util/Toast";
import { compareObjects, CheckIsObjectEmpty } from "Util/Util";
import {
    NullCheck,
    isEmail,
    PhoneNumberValidate,
    NoSpecialCharactersOrNumbers,
} from "../../../../Components/validators";
import { useSearchParams } from "react-router-dom";
function Index() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mainload, setMainLoad] = useState(true);
    const [adminroles, setadminroles] = useState([]);
    const [selectedcountry, setSelectedCountry] = useState({
        PhoneCode: "91",
        Country: "IN",
        Name: "India",
    });
    let Countries = Country.getAllCountries();
    const [savedData, setSavedData] = useState([]);
    const [SecondaryMobNo_CountryCode, SetSecondaryMobNo_CountryCode] =
        useState({ PhoneCode: "91", Country: "IN", Name: "India" });
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: { Type: "1" } });
    const formData = watch();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const adminRoles = (admin) => {
        Services.getAdminroles("GET", null, token)
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setMainLoad(false);
                }, 300);
                if (res.Status === 1) {
                    setadminroles(res.AdminRoles);
                    if (params.get("id")) {
                        reset({
                            FirstName: admin.FirstName,
                            LastName: admin.LastName,
                            MobileNumber: admin.MobileNumber,
                            EmailID: admin.EmailID,
                            Type: admin.Type,
                            Role: admin.Role,
                            ShiftFromTime: admin.ShiftFromTime,
                            ShiftToTime: admin.ShiftToTime,
                            SecondaryMobileNo: admin.SecondaryMobileNo,
                        });
                        setSavedData({
                            FirstName: admin.FirstName,
                            LastName: admin.LastName,
                            MobileNumber: admin.MobileNumber,
                            EmailID: admin.EmailID,
                            Type: admin.Type,
                            Role: admin.Role,
                            ShiftFromTime: admin.ShiftFromTime,
                            ShiftToTime: admin.ShiftToTime,
                            SecondaryMobileNo: admin.SecondaryMobileNo,
                        });
                    }
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        if (params.get("id")) {
            setEdit(true);
            Services.getAdminDetailsByID("GET", null, token, params.get("id"))
                .then((response) => {
                    if (response.Status === 1) {
                        adminRoles(response.AdminUsers);
                    } else if (response.Status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            adminRoles();
            setValue("EmailID", "");
            setValue("Password", "");
        }
    }, []);
    const phoneChangeHandler = (data) => {
        console.log(data);
        const selectedCountry = Countries.filter(
            (item) => item.isoCode == data
        );
        if (selectedCountry.length > 0) {
            console.log(selectedCountry);
            setSelectedCountry({
                PhoneCode: selectedCountry[0].phonecode,
                Country: selectedCountry[0].isoCode,
                Name: selectedCountry[0].name,
            });
        }
    };
    const SET_SECONDARY_COUNTRY = (data) => {
        const selectedCountry = Countries.filter(
            (item) => item.isoCode == data
        );
        if (selectedCountry.length > 0) {
            SetSecondaryMobNo_CountryCode({
                PhoneCode: selectedCountry[0].phonecode,
                Country: selectedCountry[0].isoCode,
                Name: selectedCountry[0].name,
            });
        }
    };
    const onSubmit = (data) => {
        localStorage.setItem("name", data.FirstName);

        if (edit) {
            let body = {
                ...data,
                CountryCode: selectedcountry.PhoneCode,
                Secondary_CountryCode: SecondaryMobNo_CountryCode.PhoneCode,
            };
            let newBody = compareObjects(body, savedData);
            if (CheckIsObjectEmpty(newBody)) {
                warningSwal("Warning", "Nothing to update");
                return;
            }
            setLoading(true);
            Services.UpdateAdmin(
                "PUT",
                JSON.stringify(newBody),
                token,
                params.get("id")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
            return;
        }
        setLoading(true);
        Services.addAdmin(
            "POST",
            JSON.stringify({
                ...data,
                CountryCode: selectedcountry.PhoneCode,
                Secondary_CountryCode: SecondaryMobNo_CountryCode.PhoneCode,
            }),
            token
        )
            .then((response) => {
                if (response.Status === 1) {
                    Navigate(-1);
                    SuccessSwal("Success", response.Message);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const backButton = () => {
        Navigate(-1);
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    console.log(formData.Type);
    return (
        <Layout Active={"Administrators"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Administrators</title>
            </Helmet>
            <div className={AddClasses["Container"]}>
                <button onClick={backButton} className={AddClasses["back"]}>
                    Back
                </button>
                <div className={AddClasses["wrapper"]}>
                    <h3>
                        {!edit ? "Add Administrator" : "Update Administrator"}
                    </h3>
                    {mainload ? (
                        <Mainloader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="First Name">
                                    First Name{" "}
                                    <span className="important">*</span>
                                </label>
                                <input
                                    {...register("FirstName", {
                                        required: "This field is required",
                                        validate: {
                                            NullCheck,
                                            NoSpecialCharactersOrNumbers,
                                        },
                                    })}
                                />
                                {errors.FirstName && (
                                    <span>{errors?.FirstName?.message}</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="Last Name">
                                    Last Name{" "}
                                    <span className="important">*</span>
                                </label>
                                <input
                                    {...register("LastName", {
                                        required: "This field is required",
                                        validate: {
                                            NullCheck,
                                            NoSpecialCharactersOrNumbers,
                                        },
                                    })}
                                />
                                {errors.LastName && (
                                    <span>{errors?.LastName?.message}</span>
                                )}
                            </div>

                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="EmailID">
                                    Email <span className="important">*</span>
                                </label>
                                <input
                                    disabled={edit}
                                    type="email"
                                    autoComplete="new-password"
                                    {...register("EmailID", {
                                        required: true,

                                        validate: isEmail,
                                    })}
                                />
                                {errors.EmailID && (
                                    <span>This field is required</span>
                                )}
                            </div>

                            {!params.get("id") && (
                                <div className={AddClasses["form-control"]}>
                                    <label htmlFor="Password">
                                        Password{" "}
                                        <span className="important">*</span>
                                    </label>
                                    <input
                                        disabled={edit}
                                        autoComplete="new-password"
                                        type="password"
                                        {...register("Password", {
                                            required: true,
                                            validate: NullCheck,
                                        })}
                                    />
                                    {errors.Password && (
                                        <span>This field is required</span>
                                    )}
                                </div>
                            )}

                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Primary number (Whatsapp Number){" "}
                                    <span className="important">*</span>
                                </label>
                                <div className={AddClasses["select-code"]}>
                                    <input
                                        disabled={edit}
                                        type="number"
                                        onWheel={blurHandler}
                                        onScroll={blurHandler}
                                        {...register("MobileNumber", {
                                            required: "this field is required",
                                            validate: PhoneNumberValidate,
                                        })}
                                    />
                                    <div>
                                        <ReactFlagsSelect
                                            searchable
                                            selected={selectedcountry.Country}
                                            showSelectedLabel={false}
                                            onSelect={phoneChangeHandler}
                                            fullWidth={false}
                                            // customLabels={customLabels}
                                            placeholder=""
                                            className={AddClasses["select-btn"]}
                                        />
                                    </div>
                                </div>
                                {errors.MobileNumber && (
                                    <span>{errors?.MobileNumber.message}</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Secondary Number
                                </label>
                                <div className={AddClasses["select-code"]}>
                                    <input
                                        disabled={edit}
                                        type="number"
                                        onWheel={blurHandler}
                                        onScroll={blurHandler}
                                        {...register("SecondaryMobileNo", {
                                            required: false,
                                            // validate: PhoneNumberValidate,
                                        })}
                                    />
                                    <div>
                                        <ReactFlagsSelect
                                            selected={
                                                SecondaryMobNo_CountryCode.Country
                                            }
                                            searchable
                                            showSelectedLabel={false}
                                            onSelect={SET_SECONDARY_COUNTRY}
                                            fullWidth={false}
                                            // customLabels={customLabels}
                                            placeholder=""
                                            className={AddClasses["select-btn"]}
                                        />
                                    </div>
                                </div>
                                {errors.SecondaryMobileNo && (
                                    <span>
                                        {errors?.SecondaryMobileNo.message}
                                    </span>
                                )}
                            </div>

                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="Type">
                                    Type <span className="important">*</span>
                                </label>
                                <select
                                    {...register("Type", {
                                        required: true,
                                    })}>
                                    <option value={1}>Admin</option>
                                    <option value={2}>Relation Manager</option>
                                    <option value={5}>Sales Manager</option>
                                    <option value={14}>Quality Checker</option>
                                </select>
                                {errors.Type && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="Type">
                                    Role <span className="important">*</span>
                                </label>
                                <select
                                    {...register("Role", {
                                        required: true,
                                    })}>
                                    {adminroles.map((item) => (
                                        <option value={item.AdminRoleID}>
                                            {item.RoleName}
                                        </option>
                                    ))}
                                </select>
                                {errors.Role && (
                                    <span>This field is required</span>
                                )}
                            </div>

                            {formData.Type != 1 && formData.Type != 14 && (
                                <div className={AddClasses["form-control"]}>
                                    <label htmlFor="Type">
                                        Shift Start Time{" "}
                                        <span className="important">*</span>
                                    </label>
                                    <select
                                        {...register("ShiftFromTime", {
                                            required: true,
                                        })}>
                                        {Preferred_Time.map((item) => (
                                            <option value={item.label}>
                                                {item.value}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.ShiftFromTime && (
                                        <span>This field is required</span>
                                    )}
                                </div>
                            )}
                            {formData.Type != 1 && formData.Type != 14 && (
                                <div className={AddClasses["form-control"]}>
                                    <label htmlFor="Type">
                                        Shift End Time{" "}
                                        <span className="important">*</span>
                                    </label>
                                    <select
                                        {...register("ShiftToTime", {
                                            required: true,
                                        })}>
                                        {Preferred_Time.map((item) => (
                                            <option value={item.label}>
                                                {item.value}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.ShiftToTime && (
                                        <span>This field is required</span>
                                    )}
                                </div>
                            )}
                            <button disabled={loading}>
                                {" "}
                                {loading ? (
                                    <ReactLoading
                                        color="green"
                                        type="spokes"
                                        height={30}
                                        width={30}
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Index;
