import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import TutorClasses from "./Tutor.module.css";
import Layout from "../../../../Components/Layout/index";
import { BiPlus } from "react-icons/bi";
import { Services } from "../../../../Services";
import SkeletonLoad from "./TableLoad/load";
import ReactPaginate from "react-paginate";
import { useNavigate, useSearchParams } from "react-router-dom";
import TutorTable from "./TutorTable";
import { Error } from "../../../../Util/Toast";
import useStore from "../../../../store";
import { IoMdSearch } from "react-icons/io";
import Tabs from "./Tabs";
function Tutor() {
    const Navigate = useNavigate();
    const [searchparams, setSearchParams] = useSearchParams();
    const [Active, setActive] = useState(searchparams.get("tab"));
    const [loading, setLoading] = useState(true);
    const [forcepage, setforcepage] = useState(0);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [admindata, setadmindata] = useState(
        JSON.parse(localStorage.getItem("admindata")) || { Type: 1 }
    );
    const RecordCount = useStore((item) => item.NoOfRecords);
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setShowPagiate] = useState(true);
    const [searchString, setSearchString] = useState("");
    const getAllTutors = (Status, skip) => {
        let body = {
            TutorStatus: Status,
            toSkip: skip,
            SearchString: searchString,
            whether_Mobile_filter: 0,
            whether_Name_filter: 0,
        };
        if (searchString.length > 0) {
            if (isNaN(searchString) || admindata?.Type == 14) {
                body = { ...body, whether_Name_filter: 1 };
            } else if (admindata?.Type != 14) {
                body = { ...body, whether_Mobile_filter: 1 };
            }
        }
        Services.GetTutors("POST", JSON.stringify(body), token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Tutors);
                    if (
                        response.Tutors.length == 0 ||
                        response.Count <= RecordCount
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setpageCount(
                            Math.ceil(
                                Number(response?.Count) / Number(RecordCount)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            let ActiveTab = searchparams.get("tab");
            let Page = searchparams.get("page");
            if (admindata.Type == 14) {
                ActiveTab = 1;
                setSearchParams({ tab: 1, page: Page });
            }
            if (ActiveTab != Active) {
                setActive(ActiveTab);
            }

            setLoading(true);

            getAllTutors(ActiveTab, (Number(Page) - 1) * RecordCount);
            setforcepage(Number(searchparams.get("page")) - 1);
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [searchString]);
    // const getAllTutors = async (data) => {
    //     try {
    //         const getTutors = await Services.GetTutors(
    //             "POST",
    //             JSON.stringify({ ApprovedStatus: data }),
    //             token
    //         );
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 200);
    //         if (getTutors.Status === 1) {
    //             setData(getTutors.Tutors);
    //         } else {
    //             Error(getTutors.Message);
    //         }
    //     } catch (err) {
    //         setLoading(false);
    //         alert("something went wrong please try again");
    //     }
    // };

    // useEffect(() => {
    //     let activeTab = searchparams.get("tab");
    //     let page = searchparams.get("page");
    //     if (page) {
    //         setCurrentPage(page - 1);
    //         startIndex = (page - 1) * itemsPerPage;
    //         endIndex = startIndex + itemsPerPage;
    //     }
    //     if (activeTab) {
    //         getAllTutors(activeTab);
    //         setActive(activeTab);
    //     } else {
    //         getAllTutors(2);
    //     }
    // }, []);
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        setSearchParams({ tab: Active, page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);
        getAllTutors(Active, selectedObject.selected * RecordCount);
        setLoading(true);
    };
    const searchHandler = (e) => {
        setSearchParams({ tab: Active, page: 1 });
        setforcepage(0);
        setSearchString(e.target.value);
    };
    return (
        <Layout Active={"Tutor"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tutor</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <div className={TutorClasses["control-btns"]}>
                    <button
                        className={TutorClasses["button"]}
                        onClick={() => {
                            Navigate("/Tutor/Add");
                        }}>
                        <BiPlus size={20} /> Create New Tutor
                    </button>
                </div>
                <div className={TutorClasses["Tutor"]}>
                    <h3>Tutors</h3>
                    <Tabs
                        setSearchParams={setSearchParams}
                        setActive={setActive}
                        setLoading={setLoading}
                        getAllTutors={getAllTutors}
                        setCurrentPage={setforcepage}
                        Active={Active}
                    />
                    <div className={TutorClasses["search"]}>
                        <input
                            type="text"
                            onChange={searchHandler}
                            value={searchString}
                            placeholder={
                                admindata?.Type == 14
                                    ? "Search by name "
                                    : "Search by name or mobile number"
                            }
                        />
                        <IoMdSearch
                            className={TutorClasses["search-icon"]}
                            size={26}
                        />
                    </div>
                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : (
                        <TutorTable data={data} />
                    )}
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={forcepage}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
