import React, { useState, useEffect } from "react";
import Layout from "../../../Components/Layout";
import Parentclasses from "./Parent.module.css";
import { Helmet } from "react-helmet";
import { BiPlus } from "react-icons/bi";
import { Services } from "../../../Services";
import { toast } from "react-toastify";
import SkeletonLoad from "./TableLoad/load";
import { useNavigate, useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import useStore from "../../../store";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
function Parent() {
    const Navigate = useNavigate();
    const [forcepage, setforcepage] = useState(0);

    const [params, setParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [searchparams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const AdminData = JSON.parse(localStorage.getItem("admindata"));
    // rEC
    const RecordCount = useStore((item) => item.NoOfRecords);
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setShowPagiate] = useState(true);
    const [searchString, setSearchString] = useState("");
    const [Active, setActive] = useState(searchparams.get("tab"));
    const getAllParents = (Status, skip) => {
        let body = {
            Status: Status,
            toSkip: skip,
            SearchString: searchString,
            whether_Name_filter: 1,
            whether_Email_filter: 1,
            whether_Mobile_filter: 1,
        };
        /*      if (searchString.length > 0) {
            if (isNaN(searchString)) {
                body = {
                    ...body,
                    whether_Name_filter: 1,
                    whether_Email_filter: 1,
                    whether_Mobile_filter: 1,
                };
            } else {
                body = { ...body, whether_Mobile_filter: 1 };
            }
        } */
        Services.listAllStudents("POST", JSON.stringify(body), token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Parents);
                    if (
                        response.Parents.length == 0 ||
                        response.Count <= RecordCount
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setpageCount(
                            Math.ceil(
                                Number(response?.Count) / Number(RecordCount)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            let ActiveTab = searchparams.get("tab");
            let Page = params.get("page");
            if (ActiveTab != Active) {
                setActive(ActiveTab);
            }
            setLoading(true);
            getAllParents(Active, (Number(Page) - 1) * RecordCount);
            setforcepage(Number(params.get("page")) - 1);
        }, 700);

        return () => clearTimeout(debouncedSearch);
    }, [searchString]);
    // useEffect(() => {
    //     let Page = params.get("page");
    //     let ActiveTab = searchparams.get("tab");
    //     if (ActiveTab && Page) {
    //         getAllParents(ActiveTab, (Number(Page) - 1) * RecordCount);
    //         setforcepage(Number(params.get("page")) - 1);

    //         setActive(ActiveTab);
    //     } else {
    //         getAllParents(1, 0);
    //     }
    // }, []);
    const NavHandler = (e) => {
        let Type = e.currentTarget.getAttribute("btn-type");
        setSearchParams({ tab: Type, page: 1 });
        setforcepage(0);
        setActive(Type);
        setLoading(true);
        getAllParents(Type, 0);
    };
    const clickHandler = (id, name) => {
        Navigate(`/parent/Details?Id=${id}`);
        localStorage.setItem("name", name);
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        setSearchParams({ tab: Active, page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);
        getAllParents(Active, selectedObject.selected * RecordCount);
        setLoading(true);
    };
    const searchHandler = (e) => {
        setSearchParams({ tab: Active, page: 1 });
        setforcepage(0);
        setSearchString(e.target.value);
    };
    const deleteService = (id) => {
        setLoading(true);
        Services.DeleteParent("DELETE", null, token, id)
            .then((response) => {
                if (response.Status === 1) {
                    setSearchParams({ tab: Active, page: 1 });

                    getAllParents(Active, 0);
                } else if (response.Status === 0) {
                    Error(response.Message);
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                Error("something went wrong please try again");
            });
    };
    const deleteHandler = (e, name, id) => {
        e.stopPropagation();
        swal(`Are You sure you want to Delete ${name}?`, {
            icon: "warning",
            className: Parentclasses["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: "Delete",
                    className: Parentclasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Delete":
                    deleteService(id);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    return (
        <Layout Active={"Parent"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Parents</title>
            </Helmet>
            <div className={Parentclasses["Container"]}>
                <div className={Parentclasses["control-btns"]}>
                    <button
                        className={Parentclasses["button"]}
                        onClick={() => {
                            Navigate("/Parent/Add");
                        }}>
                        <BiPlus size={20} /> Create Parent
                    </button>
                </div>
                <div className={Parentclasses["Tutor"]}>
                    <header>
                        <h3>Parents</h3>
                    </header>
                    <div className={Parentclasses["search"]}>
                        <input
                            type="text"
                            onChange={searchHandler}
                            value={searchString}
                            placeholder="Search by name /mobile number/ email"
                        />
                        <IoMdSearch
                            className={Parentclasses["search-icon"]}
                            size={26}
                        />
                    </div>
                    <div className={Parentclasses["Navigator"]}>
                        <button
                            btn-type="1"
                            className={
                                Active == "1" ? Parentclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Active
                        </button>
                        <button
                            btn-type="2"
                            className={
                                Active == "2" ? Parentclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Dormant
                        </button>
                    </div>
                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={Parentclasses["main-container"]}>
                            <div className={Parentclasses["Table"]}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            {AdminData.Type == 1 && (
                                                <th>Address</th>
                                            )}
                                            <th>State</th>
                                            <th>Created </th>
                                            {AdminData?.AdminUserID == 1 && (
                                                <th>Actions</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item) => (
                                            <tr
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    clickHandler(
                                                        item.ParentID,
                                                        `${item.FirstName} ${item.LastName}`
                                                    );
                                                }}>
                                                <td
                                                    style={{
                                                        maxWidth: "250px",
                                                    }}>
                                                    {item.FirstName}{" "}
                                                    {item.LastName}
                                                </td>
                                                <td>
                                                    {item?.CountryCode !=
                                                        "NA" &&
                                                        item.CountryCode}{" "}
                                                    {item.MobileNumber}
                                                </td>
                                                <td
                                                    style={{
                                                        maxWidth: "200px",
                                                    }}>
                                                    {item.EmailID}
                                                </td>
                                                {AdminData.Type === 1 && (
                                                    <td
                                                        style={{
                                                            maxWidth: "200px",
                                                        }}>
                                                        {item.Address} <br />{" "}
                                                        {/* {item.City} */}
                                                    </td>
                                                )}
                                                <td>{item.State} </td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.CreatedAt}
                                                    </Moment>{" "}
                                                </td>
                                                {AdminData?.AdminUserID ==
                                                    1 && (
                                                    <td>
                                                        <button
                                                            className={
                                                                Parentclasses[
                                                                    "Deleteclass"
                                                                ]
                                                            }
                                                            onClick={(e) => {
                                                                deleteHandler(
                                                                    e,
                                                                    `${item?.FirstName} ${item.LastName}`,
                                                                    item?.ParentID
                                                                );
                                                            }}>
                                                            <MdDelete
                                                                color="red"
                                                                size={20}
                                                            />
                                                        </button>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {showPaginate && (
                                <div
                                    style={{
                                        justifySelf: "right",
                                    }}>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        // pageRange={2}
                                        forcePage={forcepage}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Parent Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Parent;
