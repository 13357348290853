import React, { useState, useEffect } from "react";
import ProfileData from "../profileData/Profile";
import SkeletonLoad from "./Skeleton";
import ProfileClasses from "./profile.module.css";
import { Services } from "../../../../../Services";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { GrDocumentPdf } from "react-icons/gr";
import pdf from "../../../../../Assets/pdf.png";
function Profile({ loading, setLoading, data, setData, admindata }) {
    const [params] = useSearchParams();

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        Services.GetTutorDetails("GET", null, token, params.get("TutorId"))
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Tutors);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    return (
        <div>
            {loading ? (
                <SkeletonLoad />
            ) : (
                <>
                    <ProfileData data={data} admindata={admindata} />
                    <div className={ProfileClasses["profile-data"]}>
                        {admindata?.Type != 14 && (
                            <div className={ProfileClasses["data-wrap-bank"]}>
                                <h3>Account Details</h3>

                                <div>
                                    <h4>Bank Name</h4>
                                    <span>:</span>
                                    <p>{data.BankName}</p>
                                </div>
                                <div>
                                    <h4>Account Number</h4>
                                    <span>:</span>
                                    <p>{data.AccountNumber}</p>
                                </div>
                                <div>
                                    <h4>IFSC </h4>
                                    <span>:</span>
                                    <p>{data.IFSC}</p>
                                </div>
                                {/* <div>
                                <h4>Pan Number </h4>
                                <span>:</span>
                                <p>{data.PanNumber}</p>
                            </div> */}

                                <div>
                                    <h4>Canceled Cheque</h4>
                                    <span>:</span>

                                    {data.CanceledCheque &&
                                    data.CanceledCheque != "NA" ? (
                                        <img
                                            onClick={() => {
                                                window.open(
                                                    data.CanceledCheque,
                                                    "_blank"
                                                );
                                            }}
                                            src={data.CanceledCheque}
                                            alt="Canceled Cheque"
                                        />
                                    ) : (
                                        <p>NA</p>
                                    )}
                                </div>
                                <div>
                                    <h4>Pan Card</h4>
                                    <span>:</span>

                                    {data.PanNumber &&
                                    data.PanNumber != "NA" ? (
                                        <img
                                            onClick={() => {
                                                window.open(
                                                    data.PanNumber,
                                                    "_blank"
                                                );
                                            }}
                                            src={data.PanNumber}
                                            alt=" Pan Number"
                                        />
                                    ) : (
                                        <p>NA</p>
                                    )}
                                </div>
                            </div>
                        )}
                        {data.Courses.length > 0 && (
                            <div
                                className={ProfileClasses["data-wrap-courses"]}>
                                <h3>Courses :</h3>
                                <ul>
                                    {data.Courses.length > 0 &&
                                        data.Courses.map((item) => (
                                            <li>{item.CourseName}</li>
                                        ))}
                                </ul>
                            </div>
                        )}

                        {/* <div className={ProfileClasses["data-wrap"]}>
                            <h3>Teaching Skills</h3>
                            {data?.TeachingSkills?.length > 0 ? (
                                <div className={ProfileClasses["skills"]}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Subject</th>
                                                <th>Skill Level</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.TeachingSkills?.map(
                                                (item) => (
                                                    <tr>
                                                        <td>{item.skill}</td>
                                                        <td>
                                                            <span>
                                                                {item.level}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p>No Skills Found</p>
                            )}
                        </div> */}
                        <div className={ProfileClasses["Documents"]}>
                            <div className={ProfileClasses["data-wrap"]}>
                                <h3>Qualifications</h3>
                                {data?.Qualifications?.length > 0 ? (
                                    <div
                                        className={
                                            ProfileClasses["qualification"]
                                        }>
                                        {data?.Qualifications?.map((item) => (
                                            <ul>
                                                <li>
                                                    <span></span>
                                                    {item.Qualification}
                                                </li>

                                                {/* <li>
                                                    <span></span>
                                                    {item.Grade}
                                                </li> */}
                                            </ul>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No Qualifications Found</p>
                                )}
                            </div>
                            <div className={ProfileClasses["data-wrap"]}>
                                <h3>Institutions Attended</h3>
                                {data?.InstitutionsAttended?.length > 0 ? (
                                    <div
                                        className={
                                            ProfileClasses["qualification"]
                                        }>
                                        {data?.InstitutionsAttended?.map(
                                            (item) => (
                                                <ul>
                                                    <li>
                                                        <span></span>
                                                        {item}
                                                    </li>

                                                    {/* <li>
                                                    <span></span>
                                                    {item.Grade}
                                                </li> */}
                                                </ul>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <p>No Institutions Found</p>
                                )}
                            </div>
                            {/* <div className={ProfileClasses["data-wrap"]}>
                                <h3>Institutions Attended</h3>
                                <div
                                    className={ProfileClasses["qualification"]}>
                                    {data?.InstitutionsAttended?.length > 0 ? (
                                        <ul>
                                            {data?.InstitutionsAttended?.map(
                                                (item) => (
                                                    <li>{item}</li>
                                                )
                                            )}
                                        </ul>
                                    ) : (
                                        <p>No Institutions Found</p>
                                    )}
                                </div>
                            </div>
 */}
                            <div className={ProfileClasses["data-wrap"]}>
                                <h3>Uploaded Documents</h3>
                                {data?.Documents?.length > 0 ? (
                                    <div
                                        className={
                                            ProfileClasses["updateddocuments"]
                                        }>
                                        {data.Documents.map((item) => {
                                            return item?.Document?.split(
                                                "."
                                            )[1] === "pdf" ? (
                                                <img
                                                    src={pdf}
                                                    onClick={() =>
                                                        window.open(
                                                            item.Get_Documents,
                                                            "_blank"
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <img
                                                    src={item.Get_Documents}
                                                    onClick={() =>
                                                        window.open(
                                                            item.Get_Documents,
                                                            "_blank"
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <p>No Documents Found</p>
                                )}
                            </div>
                            <div className={ProfileClasses["data-wrap"]}>
                                <h3>Notes</h3>
                                {data?.Notes?.length > 0 ? (
                                    <div
                                        className={
                                            ProfileClasses["files"]
                                        }></div>
                                ) : (
                                    <p>No Notes Found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Profile;
