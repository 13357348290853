import React, { useState, useEffect } from "react";
import Scheduleclasses from "./classes.module.css";
import Layout from "../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import SkeletonLoad from "./TableLoad/load";
import Moment from "react-moment";
import useStore from "../../store";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
function Tutor() {
    const RecordCount = useStore((item) => item.NoOfRecords);
    const Navigate = useNavigate();
    const [showPaginate, setShowPagiate] = useState(true);
    const [forcepage, setforcepage] = useState(0);
    const [searchparams, setSearchParams] = useSearchParams();
    const [Active, setActive] = useState("1");
    const [loading, setLoading] = useState(true);
    const [pageCount, setpageCount] = useState(1);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [admindata] = useState(JSON.parse(localStorage.getItem("admindata")));
    const Get_Classes = (data, skip) => {
        Services.getScheduledClasses(
            "GET",
            null,
            token,
            data,
            admindata.Type,
            skip
        )
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.ScheduleClasess);
                    if (
                        response.ScheduleClasess.length == 0 ||
                        response.Count <= RecordCount
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setpageCount(
                            Math.ceil(
                                Number(response?.Count) / Number(RecordCount)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        let activeTab = searchparams.get("tab");
        let page = searchparams.get("page");
        if (activeTab) {
            Get_Classes(activeTab, (Number(page) - 1) * RecordCount);
            setActive(activeTab);
        } else {
            Get_Classes(1, 0);
        }
        // eslint-disable-next-line
    }, []);
    // eslint-disable-next-line
    const NavHandler = (e) => {
        let Type = e.currentTarget.getAttribute("btn-type");
        setSearchParams({ tab: Type, page: 1 });
        setActive(Type);
        setLoading(true);
        Get_Classes(Type, 0);
    };
    const detailsHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        let name = e.currentTarget.getAttribute("name");
        Navigate(`/scheduledClasses/Details?scheduleID=${id}`);
        localStorage.setItem("name", name);
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        setSearchParams({ tab: Active, page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);
        Get_Classes(Active, selectedObject.selected * RecordCount);
        setLoading(true);
    };
    return (
        <Layout Active={"scheduledClasses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Scheduled Classes</title>
            </Helmet>
            <div className={Scheduleclasses["Container"]}>
                <div className={Scheduleclasses["control-btns"]}>
                    {/* <button
                        className={Scheduleclasses["button"]}
                        onClick={() => {
                            Navigate("/Batches/add");
                        }}>
                        <BiPlus size={20} /> Create Batch
                    </button> */}
                    {/* <button className={Scheduleclasses["button"]}>
                        Filter
                    </button> */}
                </div>
                <div className={Scheduleclasses["Tutor"]}>
                    <h3>Classes</h3>
                    <div className={Scheduleclasses["Navigator"]}>
                        <button
                            btn-type="1"
                            className={
                                Active === "1" ? Scheduleclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Active
                        </button>
                        <button
                            btn-type="2"
                            className={
                                Active === "2" ? Scheduleclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Past
                        </button>
                        <button
                            btn-type="3"
                            className={
                                Active === "3" ? Scheduleclasses["active"] : ""
                            }
                            onClick={NavHandler}>
                            Cancelled
                        </button>
                    </div>

                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={Scheduleclasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Class</th>
                                        <th>Start Date Time</th>
                                        {Active != 3 && <th>Batch Size</th>}
                                        <th>End Time</th>
                                        {admindata.Type != 14 && (
                                            <th>Tutor Name</th>
                                        )}
                                        <th>Course Name</th>

                                        {Active == 3 && (
                                            <th>Cancellation Reason</th>
                                        )}
                                        {Active == 3 && <th>Cancelled ON</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => {
                                        const timeStr =
                                            item?.Scheduled_StartTime;

                                        // Combine date and time
                                        const dateTime = moment(
                                            item?.Scheduled_StartDate
                                        ).set({
                                            hour: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).hours(),
                                            minute: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).minutes(),
                                            second: moment(
                                                timeStr,
                                                "HH:mm:ss"
                                            ).seconds(),
                                        });
                                        return (
                                            <tr
                                                onClick={detailsHandler}
                                                id={item.ScheduleClassID}
                                                name={item.TutorName}>
                                                <td>
                                                    {item.ClassLessonName}{" "}
                                                    <br />
                                                    {Active == 3 &&
                                                        `(${item.BatchSize})`}
                                                </td>
                                                <td>
                                                    <Moment format="hh:mm A">
                                                        {dateTime}
                                                    </Moment>
                                                    <br />
                                                    <Moment format="DD-MM-YYYY">
                                                        {dateTime}
                                                    </Moment>
                                                </td>
                                                {Active != 3 && (
                                                    <td>{item.BatchSize}</td>
                                                )}
                                                <td>
                                                    {/* {UTC_TO_IST_TIME_CONVERSION(
                                                        item.Scheduled_EndTime
                                                    )} */}
                                                    {item.Scheduled_EndTime}
                                                </td>
                                                {admindata.Type != 14 && (
                                                    <td>{item.TutorName}</td>
                                                )}
                                                <td>{item.CourseName}</td>

                                                {Active == 3 && (
                                                    <td
                                                        style={{
                                                            maxWidth: "5rem",
                                                            wordBreak:
                                                                "break-word",
                                                            wordWrap:
                                                                "wrap-word",
                                                        }}>
                                                        {item.CancelReason}
                                                    </td>
                                                )}
                                                {Active == 3 && (
                                                    <td>
                                                        <Moment
                                                            format="hh:mm a Do MMM YYYY "
                                                            utc>
                                                            {item.CancelledOn}
                                                        </Moment>
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Records Found</p>
                        </div>
                    )}
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={forcepage}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
