import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import Swal from "sweetalert";
import S3FileUpload from "react-s3";
import { config } from "../../../../s3config";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import Qualification from "./Qualification";
import uuid from "react-uuid";
import { AiOutlineClose } from "react-icons/ai";
import pdf from "../../../../Assets/pdf.png";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../../Services";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import user from "../../../../Assets/user.svg";
import { compareObjects, CheckIsObjectEmpty } from "Util/Util";
import Mainloader from "../../../../Components/loader/loader";
import { Error, SuccessSwal, warningSwal } from "../../../../Util/Toast";
import { MultiSelect } from "react-multi-select-component";
import { Country, State, City } from "country-state-city";
import ReactFlagsSelect from "react-flags-select";
import {
    isEmail,
    PhoneNumberValidate,
    NoSpecialCharactersOrNumbers,
    ValidateInput,
} from "../../../../Components/validators";
window.Buffer = window.Buffer || require("buffer").Buffer;
function AddTutor() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [mainLoader, setMainLoader] = useState(true);
    const [edit, setEdit] = useState(false);
    const [uploadCheque, setUploadCheque] = useState("");
    const [deleted, setDeleted] = useState([]);
    const [base64, setBase64] = useState(user);
    const [allStates, setAllStates] = useState([]);
    const [allCities, setAllCities] = useState([]);
    const inputref = useRef();
    const [selectedcountry, setSelectedCountry] = useState({
        PhoneCode: "91",
        Country: "IN",
        Name: "India",
    });
    const [SecondaryMobNo_CountryCode, SetSecondaryMobNo_CountryCode] =
        useState({ PhoneCode: "91", Country: "IN", Name: "India" });
    const [adminData, setAdminData] = useState([]);
    const Navigate = useNavigate();
    const [admindata, setadmindata] = useState(
        JSON.parse(localStorage.getItem("admindata")) || { Type: 1 }
    );
    // eslint-disable-next-line
    const [skills, setSkills] = useState([{ skill: "", level: "" }]);
    const [qualificationDetails, setQualification] = useState([
        {
            Qualification: "",
            QualificationLevel: "",
            Institution: "",
            Grade: "",
            Year: "",
        },
    ]);
    const [fileCount, setFileCount] = useState(0);
    const [editUploadData, setEditUploadData] = useState([]);
    const [uploadFileNames, setUploadFileNames] = useState([]);
    const [Categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [updateData, setUpdateData] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [checkBody, setCheckBody] = useState([]);
    const [courses, setCourses] = useState([]);
    const [uploadpan, setuploadpan] = useState("");
    let Countries = Country.getAllCountries();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const formData = watch();
    useEffect(() => {
        FetchDropDowns();

        // eslint-disable-next-line
    }, []);
    const NullCheck_Names = (value) => {
        if (!value) {
            return "This field is required";
        }

        // Check for alphabetic characters without numbers or special characters
        if (/^[a-zA-Z ]+$/.test(value)) {
            return true; // Validation passed
        } else {
            return "Only alphabetic characters are allowed";
        }
    };
    const IFSC_VALIDATE = (value) => {
        if (value && value?.length > 0) {
            if (/^[a-zA-Z0-9 ]+$/.test(value)) {
                return true; // Validation passed
            } else {
                return "Only alpha-Numberic characters are allowed";
            }
        } else {
            return true;
        }
    };

    async function FetchDropDowns() {
        try {
            const [response1, response2, response3] = await Promise.all([
                Services.getCourses("GET", null, token),
                Services.getCategories("GET", null, token),
                Services.GetAdmin("POST", null, token),
            ]);
            if (response1.Status === 1 && response1.Courses?.length > 0) {
                setCourses(
                    response1.Courses.map((item) => {
                        return {
                            value: item.CourseID,
                            label: item.CourseName,
                        };
                    })
                );
            }
            if (response2.Status === 1 && response2.Categories?.length > 0) {
                setCategories(
                    response2.Categories.map((item) => ({
                        value: item.CategoryID,
                        label: item.CategoryName,
                    }))
                );
            }
            if (response3.Status === 1 && response3?.AdminUsers.length > 0) {
                setAdminData(
                    response3?.AdminUsers?.filter((item) => item.Type === 2)
                );
            }
            if (params.get("id")) {
                getTutorDetails(response1.Courses, response2.Categories);
                setEdit(true);
            } else {
                setTimeout(() => {
                    setMainLoader(false);
                }, 300);
            }
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    }
    function getTutorDetails() {
        Services.GetTutorDetails("GET", null, token, params.get("id"))
            .then((response) => {
                setTimeout(() => {
                    setMainLoader(false);
                }, 200);
                if (response.Status === 1) {
                    setBase64(response.Tutors.ProfilePicture);
                    let updateData = response.Tutors;
                    // delete updateData.ProfilePicture;
                    // delete updateData.PanNumber;
                    // delete updateData.CanceledCheques;
                    setUploadCheque(response.Tutors.CanceledCheque);
                    setuploadpan(response.Tutors.PanNumber);
                    setUpdateData(response.Tutors);
                    let ResponseSelectedCountry = Countries.filter(
                        (item) => item.name === response.Tutors.Country
                    );
                    let States = [];
                    if (
                        ResponseSelectedCountry &&
                        ResponseSelectedCountry.length > 0
                    ) {
                        States = State.getStatesOfCountry(
                            ResponseSelectedCountry[0].isoCode
                        );
                        setAllStates(States);
                    }

                    if (
                        response?.Tutors?.State?.length > 0 &&
                        States?.length > 0
                    ) {
                        let SelectedState = States.filter(
                            (State) => State.name === response.Tutors.State
                        );
                        console.log(SelectedState);
                        if (SelectedState && SelectedState?.length > 0) {
                            setAllCities(
                                City.getCitiesOfState(
                                    SelectedState[0].countryCode,
                                    SelectedState[0].isoCode
                                )
                            );
                        }
                    }
                    reset(updateData);
                    setCheckBody(JSON.parse(JSON.stringify(updateData)));
                    setSelectedCourses(
                        response.Tutors?.Courses?.map((item) => {
                            return {
                                value: item.CourseID,
                                label: item.CourseName,
                            };
                        })
                    );
                    setSelectedCategories(
                        response.Tutors?.Categories?.map((item) => {
                            return {
                                value: item.CategoryID,
                                label: item.CategoryName,
                            };
                        })
                    );

                    if (response?.Tutors?.Qualifications?.length > 0) {
                        setQualification(response.Tutors.Qualifications);
                    }
                    if (response?.Tutors?.TeachingSkills?.length > 0) {
                        setSkills(response.Tutors.TeachingSkills);
                    }
                    let modifiedFilesArray = response?.Tutors?.Documents?.map(
                        (item) => {
                            return {
                                fileName: item.Document,
                                DocumentID: item.DocumentID,
                                id: uuid(),
                                location: item.Get_Documents,
                                fileType: String(item.Document).split(".")[1],
                            };
                        }
                    );
                    setUploadFileNames(modifiedFilesArray);
                    setFileCount(modifiedFilesArray?.length);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }

    const onSubmit = (data) => {
        localStorage.setItem("name", `${data.FirstName} ${data.LastName}`);

        // if (qualificationDetails) {
        //     console.log("test");
        //     let error = QualificationCheck(
        //         qualificationDetails,
        //         "Oops... Qualification cannot be empty"
        //     );
        //     if (error) {
        //         return;
        //     }
        // }
        if (selectedCategories.length === 0) {
            Swal({
                icon: "error",
                title: "Select Categories",
            });
            return;
        }
        if (selectedCourses.length === 0) {
            Swal({
                icon: "error",
                title: "Select Course",
            });
            return;
        }
        let body = {
            ...data,
            // TeachingSkills: skills,
            Longitude: 0,
            Latitude: 0,
            SecondaryMobNo_CountryCode: `+${SecondaryMobNo_CountryCode.PhoneCode}`,
            CountryCode: `+${selectedcountry.PhoneCode}`,
            CourseIDs: selectedCourses.map((item) => item.value),
            Course_CategoryIDs: selectedCategories.map((item) => item.value),
            Qualifications: qualificationDetails,
            InstitutionsAttended: qualificationDetails.map(
                (item) => item.Institution
            ),
            notes: [],
        };
        // setLoading(true);
        if (edit) {
            body = {
                ...body,
                Deleted: deleted,
            };
            if (admindata?.Type == 14) {
                delete body?.PanNumber;
                delete body?.CanceledCheque;
                delete body?.IFSC;
                delete body?.BankName;
                delete body?.BankAccountName;
                delete body?.AccountNumber;
            }
            if (editUploadData.map((item) => item.fileName).length > 0) {
                body = {
                    ...body,
                    Documents: editUploadData.map((item) => item.fileName),
                };
            }
            if (
                updateData.SecondShift_RM_Morning == data.SecondShift_RM_Morning
            ) {
                delete body.SecondShift_RM_Morning;
            }
            if (
                updateData.SecondShift_RM_Evening == data.SecondShift_RM_Evening
            ) {
                delete body.SecondShift_RM_Evening;
            }
            if (updateData.Buddy_RM == data.Buddy_RM) {
                delete body.Buddy_RM;
            }
            if (updateData.Lead_RM == data.Lead_RM) {
                delete body.Lead_RM;
            }
            let newBody = compareObjects(body, checkBody);

            if (deleted.length > 0) {
                newBody = { ...newBody, Deleted: deleted };
            }
            if (CheckIsObjectEmpty(newBody)) {
                warningSwal("Warning", "Nothing to update");
                return;
            }
            if ("CourseIDs" in newBody) {
                newBody = {
                    ...newBody,
                    courseNames: selectedCourses.map((item) => item.label),
                };
            }
            Services.UpdateTutorById(
                "PUT",
                JSON.stringify(newBody),
                token,
                params.get("id")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            body = {
                ...body,
                Documents: uploadFileNames.map((item) => item.fileName),
            };
            Services.AddTutor("POST", JSON.stringify(body), token)
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const filehandleChange = (files) => {
        if (fileCount > 5 || Object.keys(files).length >= 6) {
            alert("Uploading more than 5 files is not permitted");
            return;
        }
        Object.keys(files).every((key) => {
            if (
                files[key].type === "image/png" ||
                files[key].type === "image/jpeg" ||
                files[key].type === "application/pdf"
            ) {
                const timestamp = Date.now();
                const newfile = new File(
                    [files[key]],
                    `${timestamp}.${String(files[key].type).split("/")[1]}`
                );
                let count = fileCount + Object.keys(files).length;
                if (count >= 6) {
                    alert("Uploading more than 5 files is not permitted");
                    return false;
                }
                setFileCount((prev) => prev + 1);
                S3FileUpload.uploadFile(newfile, {
                    ...config,
                    dirName: "Tutors/Documents",
                })
                    .then((res) => {
                        let data = {
                            fileType: String(files[key].type).split("/")[0],
                            location: res.location,
                            fileName: `${timestamp}.${
                                String(files[key].type).split("/")[1]
                            }`,
                            id: uuid(),
                        };
                        if (edit) {
                            setEditUploadData((prev) => [...prev, data]);
                        }
                        setUploadFileNames((prev) => [...prev, data]);
                    })
                    .catch((err) => {
                        alert(err);
                        console.log(err);
                    });
            } else {
                alert(`${files[key].name} is neither image Nor PDF`);
                return false;
            }
            return true;
        });
    };
    const profilePicUpload = (files, type) => {
        console.log(files);

        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName:
                    type == 1
                        ? "Tutors/ProfilePictures"
                        : "Tutors/TutorsDocuments",
            })
                .then((res) => {
                    if (type === 1) {
                        setValue(
                            "ProfilePicture",
                            `${timestamp}.${String(files.type).split("/")[1]}`
                        );
                        setBase64(res.location);
                    } else if (type === 2) {
                        setValue(
                            "CanceledCheque",
                            `${timestamp}.${String(files.type).split("/")[1]}`
                        );
                        setUploadCheque(res.location);
                    } else if (type === 3) {
                        setValue(
                            "PanNumber",
                            `${timestamp}.${String(files.type).split("/")[1]}`
                        );
                        setuploadpan(res.location);
                    }
                    // if (edit) {
                    //     setEditUploadData((prev) => [...prev, data]);
                    // }
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG Nor JPEG`);
            return false;
        }
        return true;
    };
    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]}>
            <BsCloudUpload className={TutorClasses["upload-icon"]} />
            <h5>Drag and Drop Files here</h5>
            <p>(Only *.jpeg ,*.png ,*.pdf will be accepted)</p>
        </div>
    );
    const profileupload = (
        <div className={TutorClasses["profile-img"]}>
            <img src={base64} alt={"profilepic"} />
        </div>
    );
    const closeHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        let docid = e.currentTarget.getAttribute("docid");
        let newFiles = uploadFileNames.filter((item) => item.id != id);
        setUploadFileNames(newFiles);
        setFileCount((prev) => prev - 1);
        if (edit) {
            console.log(id, editUploadData);
            let newFile1 = editUploadData.filter((item) => item.id != id);
            setEditUploadData(newFile1);
            if (docid != null) {
                setDeleted((prev) => [...prev, docid]);
            }
        }
    };
    const textareavalidator = (value) => {
        if (value && value.length > 160) {
            return "Maximum character limit is 160";
        }
        return true;
    };
    const phoneChangeHandler = (data) => {
        console.log(data);
        const selectedCountry = Countries.filter(
            (item) => item.isoCode == data
        );
        if (selectedCountry.length > 0) {
            console.log(selectedCountry);
            setSelectedCountry({
                PhoneCode: selectedCountry[0].phonecode,
                Country: selectedCountry[0].isoCode,
                Name: selectedCountry[0].name,
            });
        }
    };
    const SET_SECONDARY_COUNTRY = (data) => {
        console.log(data);
        const selectedCountry = Countries.filter(
            (item) => item.isoCode == data
        );
        if (selectedCountry.length > 0) {
            SetSecondaryMobNo_CountryCode({
                PhoneCode: selectedCountry[0].phonecode,
                Country: selectedCountry[0].isoCode,
                Name: selectedCountry[0].name,
            });
        }
    };
    const backButton = () => {
        Navigate(-1);
    };
    const CityHandler = () => {
        if (formData.Country == 0) {
            alert("Please select Country First");
        } else if (formData.State == 0) {
            alert("Please select State First");
        }
    };
    function StateHandler() {
        if (formData.Country == 0) {
            alert("Please select Country First");
        }
    }
    function countryChangeHandler(e) {
        setValue("State", 0);
        setValue("City", 0);
        setAllCities([]);
        const selectedCountry = Countries.filter(
            (item) => item.name == e.target.value
        );
        if (selectedCountry.length > 0) {
            // console.log());
            setAllStates(State.getStatesOfCountry(selectedCountry[0].isoCode));
        }
    }
    function stateChangeHandler(e) {
        const selectedState = allStates.filter(
            (item) => item.name == e.target.value
        );
        setValue("City", 0);
        if (selectedState.length > 0) {
            setAllCities(
                City.getCitiesOfState(
                    selectedState[0].countryCode,
                    selectedState[0].isoCode
                )
            );
        }
    }
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    return (
        <Layout Active={"Tutor"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Tutor</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    {mainLoader && <Mainloader />}
                    <h3>{!edit ? "Add Tutor" : "Update Tutor"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h3>Personal Info</h3>
                        <div className={TutorClasses["profile-upload"]}>
                            <div>
                                <FileUploader
                                    classes={
                                        TutorClasses["profile-upload-input"]
                                    }
                                    multiple={false}
                                    handleChange={(files) => {
                                        profilePicUpload(files, 1);
                                    }}
                                    name="file"
                                    children={profileupload}
                                    // types={fileTypes}
                                />
                            </div>
                        </div>

                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                First Name <span>*</span>
                            </label>
                            <input
                                {...register("FirstName", {
                                    required: "This field is required",

                                    validate: {
                                        NullCheck_Names,
                                        NoSpecialCharactersOrNumbers,
                                    },
                                })}
                            />
                            {errors.FirstName && (
                                <span>{errors.FirstName.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="Last Name">
                                Last Name <span>*</span>
                            </label>
                            <input
                                {...register("LastName", {
                                    required: "This field is required",
                                    validate: {
                                        NullCheck_Names,
                                        NoSpecialCharactersOrNumbers,
                                    },
                                })}
                            />
                            {errors.LastName && (
                                <span>{errors.LastName.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="EmailID">
                                Email <span>*</span>
                            </label>
                            <input
                                // disabled={edit}
                                type="email"
                                {...register("EmailID", {
                                    required: true,
                                    validate: isEmail,
                                })}
                            />
                            {errors.EmailID && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Contact <span>*</span>
                            </label>
                            <div className={TutorClasses["select-code"]}>
                                <input
                                    // disabled={edit}
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    type="text"
                                    {...register("MobileNumber1", {
                                        required: "This field is required",
                                        // validate: PhoneNumberValidate,
                                    })}
                                />
                                <div>
                                    <ReactFlagsSelect
                                        searchable
                                        selected={selectedcountry.Country}
                                        showSelectedLabel={false}
                                        onSelect={phoneChangeHandler}
                                        fullWidth={false}
                                        // customLabels={customLabels}
                                        placeholder=""
                                        className={TutorClasses["select-btn"]}
                                    />
                                </div>
                            </div>
                            {errors.MobileNumber1 && (
                                <span>{errors.MobileNumber1.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber2">
                                Alternative Contact<span>*</span>
                            </label>
                            <div className={TutorClasses["select-code"]}>
                                <input
                                    // disabled={edit}
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    type="text"
                                    {...register("MobileNumber2", {
                                        required: false,
                                    })}
                                />
                                <div>
                                    <ReactFlagsSelect
                                        selected={
                                            SecondaryMobNo_CountryCode.Country
                                        }
                                        searchable
                                        showSelectedLabel={false}
                                        onSelect={SET_SECONDARY_COUNTRY}
                                        fullWidth={false}
                                        // customLabels={customLabels}
                                        placeholder=""
                                        className={TutorClasses["select-btn"]}
                                    />
                                </div>
                            </div>
                            {errors.MobileNumber2 && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: "0.5rem",
                                flexDirection: "column",
                            }}>
                            <label htmlFor="Courses">
                                Categories <span className="important">*</span>
                            </label>
                            <MultiSelect
                                className="react-multi-select"
                                options={Categories}
                                value={selectedCategories}
                                onChange={setSelectedCategories}
                                labelledBy="Select"
                                placeholder="Select Categories"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                gap: "0.5rem",
                                flexDirection: "column",
                            }}>
                            <label htmlFor="Courses">
                                Courses <span className="important">*</span>
                            </label>
                            <MultiSelect
                                className="react-multi-select"
                                options={courses}
                                value={selectedCourses}
                                onChange={setSelectedCourses}
                                labelledBy="Select"
                                placeholder="Select Courses"
                            />
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="FeePerHour">
                                Fee Per Hour{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                ref={inputref}
                                // onWheel={}
                                step={"0.001"}
                                {...register("FeePerHour", {
                                    required: "This field is required",
                                    validate: (val) =>
                                        ValidateInput(
                                            val,
                                            "Fee Amount should be greater than zero"
                                        ),
                                })}
                            />
                            {errors.FeePerHour && (
                                <span>{errors.FeePerHour.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">Address</label>
                            <input
                                type="text"
                                {...register("Address", {
                                    required: false,
                                    // validate: NullCheck,
                                })}
                            />
                            {errors.Address && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">City</label>

                            <select
                                onClick={CityHandler}
                                {...register("City", {
                                    required: false,

                                    // validate: NullCheck,
                                })}
                                defaultValue={0}>
                                <option value={0} disabled>
                                    Select City
                                </option>
                                {allCities.map((item) => (
                                    <option value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            {errors.City && <span>This field is required</span>}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber" defaultValue={0}>
                                State
                            </label>
                            <select
                                onClick={StateHandler}
                                {...register("State", {
                                    required: false,
                                    onChange: (e) => {
                                        stateChangeHandler(e);
                                    },
                                    // validate: NullCheck,
                                })}
                                defaultValue={0}>
                                <option value={0} disabled>
                                    Select State
                                </option>
                                {allStates.map((item) => (
                                    <option value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                            {errors.State && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="CountryCountry">Country</label>
                            <select
                                {...register("Country", {
                                    required: false,
                                    onChange: (e) => {
                                        countryChangeHandler(e);
                                    },
                                    // validate: NullCheck,
                                })}
                                defaultValue={0}>
                                <option value={0} disabled>
                                    Select Country
                                </option>
                                {Countries.map((item) => (
                                    <option value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>

                            {errors.Country && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">Pincode</label>
                            <input
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                {...register("Pincode", {
                                    required: false,
                                    // validate: (val) =>
                                    //     ValidateInput(
                                    //         val,
                                    //         "Enter A Valid Pincode"
                                    //     ),
                                })}
                            />
                            {errors.Pincode && (
                                <span>{errors.Pincode.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">Lead RM </label>

                            <select
                                defaultValue={""}
                                {...register("Lead_RM", {
                                    required: false,
                                })}>
                                <option value="" disabled>
                                    Select Lead RM
                                </option>
                                {adminData?.map((item) => (
                                    <option value={item.AdminUserID}>
                                        {item.FirstName} {item.LastName}
                                    </option>
                                ))}
                            </select>
                            {errors.Lead_RM && (
                                <span>{errors.Lead_RM.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">Buddy RM</label>

                            <select
                                defaultValue={""}
                                {...register("Buddy_RM", {
                                    required: false,
                                })}>
                                <option value="" disabled>
                                    Select Buddy RM
                                </option>
                                {adminData?.map((item) => (
                                    <option value={item.AdminUserID}>
                                        {item.FirstName} {item.LastName}
                                    </option>
                                ))}
                            </select>
                            {errors.Buddy_RM && (
                                <span>{errors.Buddy_RM.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Second Shift RM (Early Morning)
                            </label>

                            <select
                                defaultValue={""}
                                {...register("SecondShift_RM_Morning", {
                                    required: false,
                                })}>
                                <option value="" disabled>
                                    Select SecondShift RM
                                </option>
                                {adminData?.map((item) => (
                                    <option value={item.AdminUserID}>
                                        {item.FirstName} {item.LastName}
                                    </option>
                                ))}
                            </select>
                            {errors.SecondSift_RM_Morning && (
                                <span>
                                    {errors.SecondShift_RM_Morning.message}
                                </span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Second Shift RM (Late Evening)
                            </label>

                            <select
                                defaultValue={""}
                                {...register("SecondShift_RM_Evening", {
                                    required: false,
                                })}>
                                <option value="" disabled>
                                    Select SecondShift RM
                                </option>
                                {adminData?.map((item) => (
                                    <option value={item.AdminUserID}>
                                        {item.FirstName} {item.LastName}
                                    </option>
                                ))}
                            </select>
                            {errors.SecondShift_RM_Evening && (
                                <span>
                                    {errors.SecondShift_RM_Evening.message}
                                </span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Zoom Email ID{" "}
                                {/* <span className="important">*</span> */}
                            </label>
                            <input
                                disabled
                                type="email"
                                {...register("ZoomEmailID", {
                                    required: false,
                                })}
                            />
                            {errors.ZoomEmailID && (
                                <span>{errors.ZoomEmailID.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Zoom Personal MeetingID{" "}
                                {/* <span className="important">*</span> */}
                            </label>
                            <input
                                disabled
                                type="text"
                                {...register("ZoomPersonalMeetingID", {
                                    required: false,
                                })}
                            />
                            {errors.ZoomPersonalMeetingID && (
                                <span>
                                    {errors.ZoomPersonalMeetingID.message}
                                </span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Odd Hrs Extra Fee{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                {...register("oddHrsExtraFee", {
                                    required: true,
                                    validate: (value) => {
                                        if (value < 0) {
                                            return "Fees cannot be a negative number";
                                        }
                                        return true; // Validation passed
                                    },
                                })}
                            />
                            {errors.oddHrsExtraFee && (
                                <span>{errors.oddHrsExtraFee.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Demo Class Fee
                                <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                step={0.01}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                {...register("DemoClassPrice", {
                                    required: true,
                                    validate: (value) => {
                                        if (value < 0) {
                                            return "Price cannot be a negative number";
                                        }
                                        return true; // Validation passed
                                    },
                                })}
                            />
                            {errors.DemoClassPrice && (
                                <span>{errors.DemoClassPrice.message}</span>
                            )}
                        </div>
                        <div
                            className={TutorClasses["form-control"]}
                            style={{ gridColumn: "1 / -1" }}>
                            <label htmlFor="About">About</label>
                            <textarea
                                rows="5"
                                {...register("About", {
                                    required: false,
                                    validate: textareavalidator,
                                })}></textarea>
                            {errors.About && (
                                <span>{errors.About.message}</span>
                            )}
                        </div>
                        {admindata?.Type != 14 && (
                            <div className={TutorClasses["bank-details"]}>
                                <h3>Bank account details</h3>
                                <div className={TutorClasses["form-control"]}>
                                    <label htmlFor="ContactNumber">
                                        Bank Account Number
                                    </label>
                                    <input
                                        type="number"
                                        onWheel={blurHandler}
                                        onScroll={blurHandler}
                                        {...register("AccountNumber", {
                                            required: false,
                                            validate: {
                                                minLength: (value) =>
                                                    !value ||
                                                    value.length >= 7 ||
                                                    "Acc Number should be at least 7 characters long",
                                                maxLength: (value) =>
                                                    !value ||
                                                    value.length <= 16 ||
                                                    "Acc Number should be at most 16 characters long",
                                            },
                                        })}
                                    />
                                    {errors.AccountNumber && (
                                        <span>
                                            {errors.AccountNumber.message}
                                        </span>
                                    )}
                                </div>
                                <div className={TutorClasses["form-control"]}>
                                    <label htmlFor="BankName">
                                        Tutor’s name on the Bank account
                                    </label>
                                    <input
                                        type="text"
                                        {...register("BankAccountName", {
                                            required: false,
                                        })}
                                    />
                                    {errors.BankAccountName && (
                                        <span>
                                            {errors.BankAccountName.message}
                                        </span>
                                    )}
                                </div>
                                <div className={TutorClasses["form-control"]}>
                                    <label htmlFor="BankName">
                                        {" "}
                                        Name of the Bank
                                    </label>
                                    <input
                                        type="text"
                                        {...register("BankName", {
                                            required: false,
                                        })}
                                    />
                                    {errors.BankName && (
                                        <span>{errors.BankName.message}</span>
                                    )}
                                </div>
                                <div className={TutorClasses["form-control"]}>
                                    <label htmlFor="IFSC">IFSC CODE</label>
                                    <input
                                        type="text"
                                        {...register("IFSC", {
                                            required: false,
                                            validate: IFSC_VALIDATE,
                                        })}
                                    />
                                    {errors.IFSC && (
                                        <span>{errors.IFSC.message}</span>
                                    )}
                                </div>
                                <div
                                    className={
                                        TutorClasses["file-upload-wrapper"]
                                    }>
                                    <header>
                                        <h3> Canceled Cheque</h3>
                                    </header>
                                    <FileUploader
                                        classes={TutorClasses["upload-image"]}
                                        multiple={false}
                                        handleChange={(files) => {
                                            profilePicUpload(files, 2);
                                        }}
                                        name="file"
                                        children={fileuploadDesign}
                                        // types={fileTypes}
                                    />
                                </div>
                                {uploadCheque.length > 0 && (
                                    <div
                                        className={
                                            TutorClasses["file-images-wrapper"]
                                        }>
                                        <div className={TutorClasses["image"]}>
                                            <img
                                                src={uploadCheque}
                                                onClick={() => {
                                                    window.open(
                                                        uploadCheque,
                                                        "_blank"
                                                    );
                                                }}
                                                alt="CanceledCheque"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={
                                        TutorClasses["file-upload-wrapper"]
                                    }>
                                    <header>
                                        <h3> Pan Card</h3>
                                    </header>
                                    <FileUploader
                                        classes={TutorClasses["upload-image"]}
                                        multiple={false}
                                        handleChange={(files) => {
                                            profilePicUpload(files, 3);
                                        }}
                                        name="file"
                                        children={fileuploadDesign}
                                        // types={fileTypes}
                                    />
                                </div>
                                {uploadpan?.length > 0 && (
                                    <div
                                        className={
                                            TutorClasses["file-images-wrapper"]
                                        }>
                                        <div className={TutorClasses["image"]}>
                                            <img
                                                src={uploadpan}
                                                onClick={() => {
                                                    window.open(
                                                        uploadpan,
                                                        "_blank"
                                                    );
                                                }}
                                                alt="Pan card"
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {/* <div className={TutorClasses["form-control"]}>
                            <label htmlFor="PanNumber">Pan Number</label>
                            <input
                                type="text"
                                {...register("PanNumber", {
                                    required: false,
                                })}
                            />
                            {errors.PanNumber && (
                                <span>{errors.PanNumber.message}</span>
                            )}
                        </div> */}

                        {/* <AddSkill skills={skills} setSkills={setSkills} /> */}

                        <Qualification
                            qualification={qualificationDetails}
                            setQualification={setQualification}
                        />

                        <div className={TutorClasses["file-upload-wrapper"]}>
                            <header>
                                <h3>Supporting Documents</h3>
                            </header>
                            <FileUploader
                                classes={TutorClasses["upload-image"]}
                                multiple={true}
                                handleChange={filehandleChange}
                                name="file"
                                children={fileuploadDesign}
                                // types={fileTypes}
                            />
                        </div>
                        {uploadFileNames.length > 0 && (
                            <div
                                className={TutorClasses["file-images-wrapper"]}>
                                {uploadFileNames.map((item) => (
                                    <div className={TutorClasses["image"]}>
                                        {item.fileType == "application" ||
                                        item.fileType == "pdf" ? (
                                            <img
                                                src={pdf}
                                                onClick={() => {
                                                    window.open(
                                                        item.location,
                                                        "_blank"
                                                    );
                                                }}
                                                alt="pdf"
                                            />
                                        ) : (
                                            <img
                                                src={item.location}
                                                onClick={() => {
                                                    window.open(
                                                        item.location,
                                                        "_blank"
                                                    );
                                                }}
                                            />
                                        )}
                                        <button
                                            type="button"
                                            onClick={closeHandler}
                                            id={item.id}
                                            docid={item.DocumentID}>
                                            <AiOutlineClose
                                                color={"white"}
                                                className={TutorClasses["icon"]}
                                            />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default AddTutor;
