import React, { useEffect, useState } from "react";
import Layout from "../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../Services";
import ReactLoading from "react-loading";
import { SuccessSwal, warningSwal } from "../../Util/Toast";
import moment from "moment";
import Table from "./Table";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [Sales, setSales] = useState([]);
    const [Result, setResult] = useState({ Result: [], CsvFile: "" });
    const [count, setCount] = useState({
        DemoClassesCount: 0,
        SM_DemoRequests: 0,
    });
    const [link, setLink] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getSalesManager = () => {
        setLoading(true);
        Services.list_salesmanger("GET", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setSales(response?.AdminUsers);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        // getSalesManager();
        exportHandler({ Type: "All_DemoRequests" });
    }, []);
    const onSubmit = (data) => {
        setLoading(true);
        let body = { Type: data?.Type };
        if (data.FromDate && !data.ToDate) {
            body = { ...body, ToDate: data.FromDate, FromDate: data.FromDate };
        }
        if (!data.FromDate && data.ToDate) {
            body = { ...body, FromDate: data.ToDate, ToDate: data.ToDate };
        }
        if (data.FromDate && data.ToDate) {
            body = { ...body, FromDate: data.FromDate, ToDate: data?.ToDate };
        }
        exportHandler(body);
    };
    function getTimeDifferenceInMinutes(
        ActualStartDate,
        ActualStartTime,
        ActualEndDate,
        ActualEndTime
    ) {
        // Combine date and time into Date objects
        const startDateTime = new Date(
            `${ActualStartDate}T${ActualStartTime}Z`
        );
        const endDateTime = new Date(`${ActualEndDate}T${ActualEndTime}Z`);

        // Calculate the difference in milliseconds
        const timeDifferenceMs = endDateTime - startDateTime;

        // Convert milliseconds to minutes
        const minutes = Math.floor(timeDifferenceMs / (1000 * 60));

        return minutes;
    }
    const exportHandler = (body) => {
        setLoading(true);
        Services.exportDemoRequests("POST", JSON.stringify(body), token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    if (body.Type == "All_DemoRequests") {
                        setResult({
                            CsvFile: response?.CsvFile,
                            Result: response?.Results.map((item) => ({
                                "Requested Date": moment(item?.RequestedDate)
                                    .utc()
                                    .format("DD-MM-YYYY"),
                                "Parent Name": item?.ParentName,
                                "Student Name": item?.StudentName,
                                "Mobile Number": item?.MobileNumber,
                                "Course Name": item?.CourseName,
                                "Request Created": item?.RequestCreatedByName,
                                "Claimed By": item?.ClaimedByName,
                            })),
                        });
                    }
                    if (body.Type == "Completed_Class_Students") {
                        setResult({
                            CsvFile: response?.CsvFile,
                            Result: response?.Results.map((item) => ({
                                "Parent Name": item?.ParentName,
                                "Child Name": item?.ChildName,
                                "Mobile Number": item?.ParentPhoneNumber,
                                "Course Name": item?.CourseName,
                                "Batch Size": item?.BatchSize,
                                "Start Date": moment(
                                    item?.DemoStartDate
                                ).format("DD-MM-YYYY"),
                                "Start Time": item?.DemoStartTime,
                                Attendance:
                                    item?.Attendance == 1
                                        ? "Present"
                                        : "Absent",
                                Tutor: item?.TutorName,
                                "Lead Owner": item?.LeadOwner,
                            })),
                        });
                    }
                    if (body.Type == "Completed_Demo_Classes") {
                        setResult({
                            CsvFile: response?.CsvFile,
                            Result: response?.Results.map((item) => ({
                                "Course Name": item?.CourseName,
                                "Start Date": moment(
                                    item?.DemoStartDate
                                ).format("DD-MM-YYYY"),
                                "Start Time": item?.DemoStartTime,
                                Teacher: item?.TutorName,
                                "Batch Size": item?.BatchSize,
                                "Created By": item?.DemoCreatedByName,
                                "Actual Start Time":
                                    item?.ActualStartTime != "00:00:00"
                                        ? item?.ActualStartTime
                                        : "NA",
                                "Actual End Time":
                                    item?.ActualEndTime != "00:00:00"
                                        ? item?.ActualEndTime
                                        : "NA",
                                Duration: item?.DurationInMinutes
                                    ? `${item?.DurationInMinutes} Min`
                                    : "",
                            })),
                        });
                    }
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <Layout Active={"Demo Reports"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Demo Reports</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <div className={TutorClasses["wrapper"]}>
                    <h3
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                        }}>
                        Reports{" "}
                    </h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/*   <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">Sales Manager </label>
                            <select
                                {...register("AdminUserID", {
                                    required: false,
                                })}>
                                {Sales?.map((item) => (
                                    <option value={item?.AdminUserID}>
                                        {item?.FirstName} {item?.LastName}
                                    </option>
                                ))}
                            </select>
                            {errors.AdminUserID && (
                                <span>This field is required</span>
                            )}
                        </div> */}
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">From Date </label>
                            <input
                                type="date"
                                {...register("FromDate", {
                                    required: false,
                                })}
                            />
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">To Date </label>
                            <input
                                type="date"
                                {...register("ToDate", {
                                    required: false,
                                })}
                            />
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">Type </label>
                            <select
                                {...register("Type", {
                                    required: false,
                                })}>
                                <option value="All_DemoRequests">
                                    All Demo Requests
                                </option>
                                <option value="Completed_Class_Students">
                                    Completed Class Students
                                </option>
                                <option value="Completed_Demo_Classes">
                                    Completed Demo Classes
                                </option>
                            </select>
                        </div>

                        <button
                            disabled={loading}
                            style={{ height: "fit-content" }}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                        {!loading && Result.Result.length > 0 && (
                            <div
                                className={TutorClasses["downloadContent"]}
                                style={{ alignSelf: "center" }}>
                                <button
                                    onClick={() =>
                                        window.open(Result?.CsvFile, "_blank")
                                    }>
                                    Export
                                </button>
                            </div>
                        )}
                    </form>

                    {Result.Result.length > 0 && (
                        <div className={TutorClasses["downloadContent"]}>
                            <Table data={Result?.Result} />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
