import React from "react";
import TutorClasses from "./Tutor.module.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Moment from "react-moment";
function TutorTable({ data }) {
    const [admindata, setadmindata] = useState(
        JSON.parse(localStorage.getItem("admindata")) || { Type: 1 }
    );
    const Navigate = useNavigate();
    const clickHandler = (id, name, e) => {
        let status = e.currentTarget.getAttribute("status");
        Navigate(`/Tutor/Details?TutorId=${id}&tab=Profile`);
        localStorage.setItem("name", name);
        localStorage.setItem("status", status);
    };
    return (
        <>
            <div className={TutorClasses["Table"]}>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            {admindata.Type != 14 && <th>Mobile</th>}
                            {admindata.Type != 14 && <th>Fee Per Hour</th>}
                            {admindata?.Type != 14 && <th>Email</th>}
                            {admindata.Type != 14 && <th>State</th>}
                            {admindata?.Type != 14 && <th>Created </th>}
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data?.map((item) => (
                                <tr
                                    status={item.TutorStatus}
                                    onClick={(e) => {
                                        clickHandler(
                                            item.TutorID,
                                            `${item.TutorName}`,
                                            e
                                        );
                                    }}>
                                    <td
                                        style={{
                                            maxWidth: "150px",
                                            wordBreak: "break-word",
                                        }}>
                                        {item.TutorName}{" "}
                                    </td>
                                    {admindata.Type != 14 && (
                                        <td>
                                            {item?.CountryCode != "NA" &&
                                                item.CountryCode}{" "}
                                            {item.MobileNumber1}
                                        </td>
                                    )}
                                    {admindata.Type != 14 && (
                                        <td>₹ {item.FeePerHour}</td>
                                    )}
                                    {admindata?.Type != 14 && (
                                        <td
                                            style={{
                                                maxWidth: "200px",
                                                wordBreak: "break-word",
                                            }}>
                                            {item.EmailID}
                                        </td>
                                    )}
                                    {admindata?.Type != 14 && (
                                        <td>{item.State} </td>
                                    )}
                                    {admindata?.Type != 14 && (
                                        <td>
                                            <Moment format="DD-MM-YYYY">
                                                {item.CreatedAt}
                                            </Moment>{" "}
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td style={{ textAlign: "center" }} colSpan={7}>
                                    No Tutor Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TutorTable;
