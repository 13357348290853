import React, { useState, useEffect } from "react";
import ReportClasses from "./report.module.css";
import Loader from "Components/loader/loader";
import { IoInformationCircleSharp } from "react-icons/io5";
import { useForm } from "react-hook-form";
import moment from "moment";
import { warningSwal } from "Util/Toast";
import { Services } from "Services";
import ReactPaginate from "react-paginate";
import Moment from "react-moment";
import Select from "react-select";

import { useSearchParams } from "react-router-dom";
import Layout from "Components/Layout";
function Report() {
    const [SearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [showPaginate, setShowPagiate] = useState(false);
    const [pageCount, setpageCount] = useState(1);

    let RecordCount = 20;

    const [data, setData] = useState([]);
    const [Active, setActive] = useState("TUTOR");
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);

    const [forcepage, setforcepage] = useState(0);
    const [tutorSearchString, setTutorSearchString] = useState("");
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutors, setTutors] = useState([]);
    const [link, setLink] = useState("");

    const token = {
        Authorization: `token ${localStorage.getItem("token")}`,
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {},
    } = useForm();
    const WatchData = watch();
    const formData = watch();

    // const ApiCall = async (data) => {
    //     setLoading(true);
    //     try {
    //         let body = JSON.stringify({
    //             ...data,
    //         });
    //         const [summaryData] = await Promise.all([
    //             Services.tutor_cancel_reports("POST", body, token),
    //         ]);

    //         if (summaryData.Status === 1) {
    //             getBatchRequests(Active, 0);
    //             setforcepage(0);
    //             let Parent = summaryData?.CancelReports.find(
    //                 (item) => item?.CancelType == "Parent"
    //             );
    //             if (Parent) {
    //                 setParent(Parent);
    //             }
    //             let Other = summaryData?.CancelReports.find(
    //                 (item) => item?.CancelType == "Other"
    //             );
    //             if (Other) {
    //                 setOthers(Other);
    //             }
    //             let Tutor = summaryData?.CancelReports.find(
    //                 (item) => item?.CancelType == "Tutor"
    //             );
    //             if (Tutor) {
    //                 setTeachers(Tutor);
    //             }

    //         }
    //     } catch {
    //         setLoading(false);
    //         alert("something went wrong try again");
    //     }
    // };

    // ---GET BATCH REQUESTS

    async function getBatchRequests(skip, data) {
        try {
            let body = {
                // CancelledByType: Type,
                toSkip: skip,
                EndDate: data?.EndDate,
                StartDate: data?.StartDate,
            };
            if (selectedTutor?.value) {
                body = { ...body, TutorID: selectedTutor?.value };
            }
            const response = await Services.cancelledclasses_by_type(
                "POST",
                JSON.stringify(body),
                token
            );

            setTimeout(() => {
                setLoading(false);
            }, 200);
            if (response?.Status == 1) {
                setData(response.CancelReports);
                setLink(response?.CsvFile);
                if (
                    response.CancelReports.length == 0 ||
                    response.ClassesCount <= RecordCount
                ) {
                    setShowPagiate(false);
                } else {
                    if (!showPaginate) {
                        setShowPagiate(true);
                    }
                    setpageCount(
                        Math.ceil(Number(response?.Count) / Number(RecordCount))
                    );
                }
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            Error("something went wrong please try again");
        }
    }
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString?.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors?.filter(
                        (item) => item.TutorStatus == 1
                    )?.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                        Email: tutor.EmailID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);
    const onSubmit = async (data) => {
        setLoading(true);
        if (moment(data.StartDate).isAfter(data.EndDate)) {
            warningSwal("Warning", "Invalid Date range");
            return;
        }
        setActive(data.Type);
        getBatchRequests(0, data);
    };

    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();

        window.scrollTo({ top: 10, behavior: "smooth" });
        setforcepage(selectedObject.selected);
        getBatchRequests(selectedObject.selected * RecordCount, formData);
        setLoading(true);
    };
    const onTutorSelect = (searchString) => {
        setSelectedTutor(searchString);
    };
    const handleTutorChange = (searchString) => {
        setTutorSearchString(searchString);
    };
    return (
        <Layout Active={"Cancellation Report"}>
            <div className={ReportClasses["Container"]}>
                {loading && <Loader />}
                <div className={ReportClasses["wrapper"]}>
                    <header>
                        <div>
                            <h3>Cancellation Report </h3>
                        </div>
                        <form
                            className={ReportClasses["filter"]}
                            onSubmit={handleSubmit(onSubmit)}>
                            <div className={ReportClasses["inputs"]}>
                                <input
                                    type="text"
                                    placeholder="From Date"
                                    {...register("StartDate", {
                                        required: true,
                                    })}
                                    onClick={(event) => {
                                        const inputElement = event.target;
                                        inputElement.type = "date";
                                    }}
                                />
                                <input
                                    type="text"
                                    placeholder="To Date"
                                    {...register("EndDate", {
                                        required: true,
                                    })}
                                    onClick={(event) => {
                                        const inputElement = event.target;
                                        inputElement.type = "date";
                                    }}
                                />
                                <Select
                                    options={tutors}
                                    onInputChange={handleTutorChange}
                                    value={selectedTutor}
                                    onChange={onTutorSelect}
                                    placeholder="Search Tutor"
                                    // noOptionsMessage={() => "No Student Found"}
                                    isLoading={tutorSearchLoad}
                                    styles={{
                                        // Set the width to 100%
                                        control: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                        }),
                                    }}
                                />
                                {/*      <select
                                    {...register("Type", {
                                        required: true,
                                    })}>
                                    <option value="">Select Type</option>
                                    <option value="TUTOR">TUTOR</option>
                                    <option value="PARENT">PARENT</option>
                                    <option value="OTHERS">OTHERS</option>
                                </select> */}
                                <button>Submit</button>
                            </div>
                            {data.length > 0 && (
                                <div
                                    className={ReportClasses["downloadContent"]}
                                    style={{ overflow: "auto" }}>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            window.open(link, "_blank")
                                        }>
                                        Export
                                    </button>
                                </div>
                            )}
                        </form>
                    </header>
                    {/*     <div className={ReportClasses["earnings"]}>
                        <div>
                            <p>No. of classes cancelled by the teacher </p>
                            <h3>{Teachers?.NoOfClassesCancelled}</h3>
                            <p>(impacted hours)</p>
                            <h3 style={{ fontSize: "1.2rem" }}>
                                {Teachers?.ImpactedHours != 0 &&
                                    `${Teachers.ImpactedHours} Hour`}{" "}
                                {Teachers?.ImpactedMinutes != 0 &&
                                    `${Teachers.ImpactedMinutes} Minutes`}
                                {Teachers?.ImpactedMinutes == 0 &&
                                    Teachers?.ImpactedHours == 0 &&
                                    `0 Minutes`}
                            </h3>
                        </div>
                        <div>
                            <p>No. of classes cancelled by the parent</p>
                            <h3>{Parent?.NoOfClassesCancelled}</h3>
                            <p>(impacted hours)</p>
                            <h3 style={{ fontSize: "1.2rem" }}>
                                {Parent?.ImpactedHours != 0 &&
                                    `${Parent.ImpactedHours} Hour`}{" "}
                                {Parent?.ImpactedMinutes != 0 &&
                                    `${Parent.ImpactedMinutes} Minutes`}
                                {Parent?.ImpactedMinutes == 0 &&
                                    Parent?.ImpactedHours == 0 &&
                                    `0 Minutes`}
                            </h3>
                        </div>
                        <div>
                            <p>No. of classes cancelled by the Others</p>
                            <h3>{Others?.NoOfClassesCancelled}</h3>
                            <p>(impacted hours)</p>
                            <h3
                                style={{
                                    color: "blue",
                                    fontSize: "1.2rem",
                                }}>
                                {Others?.ImpactedHours != 0 &&
                                    `${Others.ImpactedHours} Hour`}{" "}
                                {Others?.ImpactedMinutes != 0 &&
                                    `${Others.ImpactedMinutes} Minutes`}
                                {Others?.ImpactedMinutes == 0 &&
                                    Others?.ImpactedHours == 0 &&
                                    `0 Minutes`}
                            </h3>
                        </div>
                    </div> */}
                    <div className={ReportClasses["Tutor"]}>
                        <div className={ReportClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Class</th>

                                        <th>Start Date Time</th>
                                        {/* <th>End Date Time</th> */}
                                        <th>Duration</th>
                                        <th>Batch Name</th>
                                        {/* <th>Cancellation Type</th> */}
                                        <th>Cancel Reason</th>
                                        <th>Cancelled By</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        data.map((item, index) => {
                                            const timeStr = item?.DemoStartTime;

                                            // Combine date and time
                                            const dateTime = moment(
                                                item?.DemoStartDate
                                            ).set({
                                                hour: moment(
                                                    timeStr,
                                                    "HH:mm:ss"
                                                ).hours(),
                                                minute: moment(
                                                    timeStr,
                                                    "HH:mm:ss"
                                                ).minutes(),
                                                second: moment(
                                                    timeStr,
                                                    "HH:mm:ss"
                                                ).seconds(),
                                            });
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.ClassLessonName}
                                                    </td>

                                                    <td>
                                                        <Moment format="DD-MM-YYYY">
                                                            {
                                                                new Date(
                                                                    item.Scheduled_StartDate
                                                                )
                                                            }
                                                        </Moment>{" "}
                                                        <br />
                                                        {String(
                                                            item.Scheduled_StartTime
                                                        )}
                                                    </td>
                                                    {/*  <td>
                                                        <Moment format="DD-MM-YYYY">
                                                            {
                                                                new Date(
                                                                    item?.Scheduled_EndDate
                                                                )
                                                            }
                                                        </Moment>{" "}
                                                        <br />
                                                        {String(
                                                            item.Scheduled_EndTime
                                                        )}
                                                    </td> */}
                                                    <td>
                                                        {" "}
                                                        {item.ClassDuration} Min
                                                    </td>
                                                    <td>
                                                        {item.BatchName} <br />
                                                    </td>
                                                    {/*  <td>
                                                        {item.CancelledByType ==
                                                        1
                                                            ? "Administrator"
                                                            : item.CancelledByType ==
                                                              2
                                                            ? "RM"
                                                            : item.CancelledByType ==
                                                              3
                                                            ? "Tutor"
                                                            : item.CancelledByType ==
                                                              4
                                                            ? "Parent"
                                                            : "SalesManager"}
                                                    </td> */}
                                                    <td
                                                        style={{
                                                            wordBreak:
                                                                "break-word",
                                                            maxWidth: "3rem",
                                                        }}>
                                                        {item.CancelReason}
                                                    </td>
                                                    <td>
                                                        {item?.CancelledByType}
                                                    </td>
                                                    <td>{item?.Comments}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>
                                                {" "}
                                                no records Found
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {/*    {showPaginate && (
                            <div
                                style={{
                                    justifySelf: "right",
                                }}>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    // pageRange={2}
                                    forcePage={forcepage}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    containerClassName={"pagination"}
                                    pageClassName={"page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Report;
