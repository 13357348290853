import { Error_Dark } from "./Util/Toast";
const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,

        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.Status === 0) {
                if (
                    res.Message == "Invalid Token" ||
                    res.Message == "Token expired"
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/";
                    Error_Dark("Token Expired");
                }
            }
            return res;
        });
};

const Login = (method, body) => {
    return httpcall("adminuser/login", method, body);
};
const GetAdmin = (method, body, token) => {
    return httpcall("adminuser/list_all_admin_users", method, body, token);
};
const addAdmin = (method, body, token) => {
    return httpcall("adminuser/add_adminuser", method, body, token);
};
const getAdminDetailsByID = (method, body, token, adminId) => {
    return httpcall(`adminuser/admindetails/${adminId}`, method, body, token);
};
const DeleteParent = (method, body, token, parentId) => {
    return httpcall(
        `adminuser/delete_account/${parentId}`,
        method,
        body,
        token
    );
};
const getLinkedTutorByAdminId = (method, body, token) => {
    return httpcall(`adminuser/rm_linked_tutors`, method, body, token);
};
const UpdateAdmin = (method, body, token, adminId) => {
    return httpcall(
        `adminuser/update_adminuser/${adminId}`,
        method,
        body,
        token
    );
};
const getParentDetailsByNumber = (method, body, token) => {
    return httpcall(
        `parent/get_parentdetails_with_students`,
        method,
        body,
        token
    );
};
const GetTutors = (method, body, token) => {
    return httpcall("tutors/admin_list_all_tutors", method, body, token);
};
const AddTutor = (method, body, token) => {
    return httpcall("tutors/admin_add_tutor", method, body, token);
};
const GetTutorDetails = (method, body, token, id) => {
    return httpcall(`tutors/admin_tutordetails/${id}`, method, body, token);
};
const UpdateTutorById = (method, body, token, tutorId) => {
    return httpcall(
        `tutors/admin_update_tutor/${tutorId}`,
        method,
        body,
        token
    );
};
const GetStudents = (method, body, token) => {
    return httpcall("adminuser/list_all_students", method, body, token);
};
const getStudentDetailsByID = (method, body, token, studentId) => {
    return httpcall(
        `adminuser/student_details/${studentId}`,
        method,
        body,
        token
    );
};
const AddStudent = (method, body, token) => {
    return httpcall(`adminuser/add_student`, method, body, token);
};
const listAllStudents = (method, body, token) => {
    return httpcall(`adminuser/list_all_parents`, method, body, token);
};
const ListAllParents = (method, body, token) => {
    return httpcall(`adminuser/list_all_parents`, method, body, token);
};
const ListAllParents_Dropdown = (method, body, token) => {
    return httpcall(`adminuser/list_active_parents`, method, body, token);
};
const updateStudent = (method, body, token, id) => {
    return httpcall(`adminuser/update_student/${id}`, method, body, token);
};

const getParentDetailsByID = (method, body, token, parentId) => {
    return httpcall(
        `adminuser/parent_details/${parentId}`,
        method,
        body,
        token
    );
};
const AddParent = (method, body, token) => {
    return httpcall(`adminuser/add_parent`, method, body, token);
};
const UpdateParent = (method, body, token, id) => {
    return httpcall(`adminuser/update_parent/${id}`, method, body, token);
};

const getCategories = (method, body, token) => {
    return httpcall(`admin_list_all_categories`, method, body, token);
};
const getCategoriesdetailsById = (method, body, token, id) => {
    return httpcall(`admin_category_details/${id}`, method, body, token);
};
const addCategories = (method, body, token) => {
    return httpcall(`admin_add_category`, method, body, token);
};
const updateCategories = (method, body, token, id) => {
    return httpcall(`admin_update_category/${id}`, method, body, token);
};
const getCourses = (method, body, token) => {
    return httpcall(`admin_list_all_courses`, method, body, token);
};
const allCourses = (method, body, token) => {
    return httpcall(`admin_add_course`, method, body, token);
};
const getcourseDetailById = (method, body, token, id) => {
    return httpcall(`admin_course_details/${id}`, method, body, token);
};
const updateCourses = (method, body, token, id) => {
    return httpcall(`admin_update_course/${id}`, method, body, token);
};
const getLessons = (method, body, token) => {
    return httpcall(`admin_list_all_lessons`, method, body, token);
};
const addLessons = (method, body, token) => {
    return httpcall(`admin_add_lesson`, method, body, token);
};
const Updatelesson = (method, body, token, id) => {
    return httpcall(`admin_update_lesson/${id}`, method, body, token);
};
const lessonGetById = (method, body, token, id) => {
    return httpcall(`admin_lesson_details/${id}`, method, body, token);
};
const deletecourseQuestion = (method, body, token, id) => {
    return httpcall(`admin_delete_courseQA/${id}`, method, body, token);
};
const Enrolled_List = (method, body, token) => {
    return httpcall(`adminuser/courses_enrolled_list`, method, body, token);
};
const Demo_Request = (method, body, token) => {
    return httpcall(`adminuser/courses_demo_requests`, method, body, token);
};
const Demo_Request_Search = (method, body, token, mobile) => {
    return httpcall(
        `adminuser/courses_demo_requests?MobileNumber=${mobile}`,
        method,
        body,
        token
    );
};
const Get_Classes = (method, body, token) => {
    return httpcall(`adminuser/get_batches`, method, body, token);
};
const create_batch = (method, body, token) => {
    return httpcall(`adminuser/create_batch`, method, body, token);
};
const updateBatchDetails = (method, body, token, id) => {
    return httpcall(`adminuser/update_batch/${id}`, method, body, token);
};
const parentWallet = (method, body, token, id) => {
    return httpcall(
        `adminuser/parent_wallets?ParentID=${id}`,
        method,
        body,
        token
    );
};
const assignDemo = (method, body, token, id) => {
    return httpcall(`adminuser/AssignDemoRequests`, method, body, token);
};
const removeDemoStudent = (method, body, token, id) => {
    return httpcall(`adminuser/remove_demostudent`, method, body, token);
};

const searchParentByMobileNumber = (
    method,
    body,
    token,
    mobile,
    courseId,
    batchSize
) => {
    return httpcall(
        `adminuser/enrolled_list_by_parent?MobileNumber=${mobile}&CourseID=${courseId}&BatchSize=${batchSize}`,
        method,
        body,
        token
    );
};
const getbatchDetails = (method, body, token, id) => {
    return httpcall(
        `adminuser/get_batch_details?BatchID=${id}`,
        method,
        body,
        token
    );
};
const getScheduledClasses = (method, body, token, id, type, skip) => {
    return httpcall(
        `adminuser/get_Scheduled_Classes?Status=${id}&Type=${type}&toSkip=${skip}`,
        method,
        body,
        token
    );
};
const getScheduledClassesDetails = (method, body, token, id) => {
    return httpcall(
        `adminuser/get_schedule_class_details?ScheduleClassID=${id}`,
        method,
        body,
        token
    );
};
const classDetailsByBatchId = (method, body, token, batchId) => {
    return httpcall(
        `adminuser/get_Scheduled_Classes_by_batch?BatchID=${batchId}`,
        method,
        body,
        token
    );
};
const addStudentToBatch = (method, body, token) => {
    return httpcall(`adminuser/add_student_to_batch`, method, body, token);
};
const deleteStudent = (method, body, token, id) => {
    return httpcall(`adminuser/update_batchstudent/${id}`, method, body, token);
};
const createClasses = (method, body, token) => {
    return httpcall(`adminuser/Schedule_Class`, method, body, token);
};
const enquiry_List = (method, body, token) => {
    return httpcall(`adminuser/enquires_list`, method, body, token);
};
const Contact_us = (method, body, token) => {
    return httpcall(`adminuser/ContactUs_Form_List`, method, body, token);
};
const Complaints = (method, body, token) => {
    return httpcall(`adminuser/complaints_list`, method, body, token);
};
const update_batchDuration = (method, body, token) => {
    return httpcall(`adminuser/update_batchduration`, method, body, token);
};
const CancelClass = (method, body, token, ScheduleClassID) => {
    return httpcall(
        `adminuser/cancel_schedule_class/${ScheduleClassID}`,
        method,
        body,
        token
    );
};
const getAdminroles = (method, body, token) => {
    return httpcall(`adminuser/adminrolelist`, method, body, token);
};
const addrole = (method, body, token) => {
    return httpcall(`adminuser/addadminrole`, method, body, token);
};
const updaterole = (method, body, token, roleid) => {
    return httpcall(`adminuser/updateadminrole/${roleid}`, method, body, token);
};
const DeleteRole = (method, body, token, roleid) => {
    return httpcall(`adminuser/deleteadminrole/${roleid}`, method, body, token);
};
const Reschedule = (method, body, token, roleid) => {
    return httpcall(
        `adminuser/reschedule_schedule_class/${roleid}`,
        method,
        body,
        token
    );
};
const Notifications = (method, body, token, userType, userId) => {
    return httpcall(
        `adminuser/get_scheduleclass_notifications?Type=${userType}&ID=${userId}`,
        method,
        body,
        token
    );
};
const NotificationsByscheduleClassId = (
    method,
    body,
    token,
    userType,
    userId,
    classId
) => {
    return httpcall(
        `adminuser/scheduleclass_notifications_by_scheduleclass?Type=${userType}&ID=${userId}&ScheduleClassID=${classId}`,
        method,
        body,
        token
    );
};
const Approvals = (method, body, token, Type) => {
    return httpcall(
        `adminuser/Scheduled_Class_approvals?Type=${Type}`,
        method,
        body,
        token
    );
};
const ApprovalCancelrequest = (method, body, token, id) => {
    return httpcall(`adminuser/approve_requests/${id}`, method, body, token);
};
const updateEnrolledCourse = (method, body, token, id) => {
    return httpcall(`adminuser/update_enrollcourse/${id}`, method, body, token);
};
const paymentStatus = (method, body, token, id) => {
    return httpcall(`adminuser/mark_as_paid/${id}`, method, body, token);
};
const get_all_scheduledClasses = (method, body, token) => {
    return httpcall(
        `adminuser/get_Scheduled_Classes_for_calender`,
        method,
        body,
        token
    );
};
const searchActiveTutor = (method, body, token, search) => {
    return httpcall(`tutors/admin_search_active_tutors`, method, body, token);
};

const searchActiveStudent = (method, body, token) => {
    return httpcall(`adminuser/search_active_students`, method, body, token);
};
const Claim_Enroll_Course = (method, body, token, CourseId) => {
    return httpcall(
        `adminuser/Claim_EnrollCourse/${CourseId}`,
        method,
        body,
        token
    );
};
const Claim_Enroll_Course_demo = (method, body, token, CourseId) => {
    return httpcall(
        `adminuser/claim_demoregistration/${CourseId}`,
        method,
        body,
        token
    );
};
const enrolled_List_Filter = (method, body, token) => {
    return httpcall(
        `adminuser/courses_enrolled_list_filter`,
        method,
        body,
        token
    );
};
const Batch_List_Filter = (method, body, token) => {
    return httpcall(`adminuser/get_batches_filters`, method, body, token);
};
const enrolled_List_Details = (method, body, token, id) => {
    return httpcall(
        `adminuser/enrolled_list_details/${id}`,
        method,
        body,
        token
    );
};
const Move_from_Draft_Batch = (method, body, token, BatchId) => {
    return httpcall(
        `adminuser/batch_movefromdraft/${BatchId}`,
        method,
        body,
        token
    );
};
const get_Course_Lessons = (method, body, token, CourseId) => {
    return httpcall(
        `adminuser/get_lessonplan_by_course/${CourseId}`,
        method,
        body,
        token
    );
};
const add_Lesson_plan = (method, body, token) => {
    return httpcall(`adminuser/add_lessonplan`, method, body, token);
};
const GET_lessonplanDetails = (method, body, token, LessonPlanID) => {
    return httpcall(
        `adminuser/get_lessonplan_details/${LessonPlanID}`,
        method,
        body,
        token
    );
};
const updateLesson = (method, body, token, LessonPlanID) => {
    return httpcall(
        `adminuser/Update_Lesson/${LessonPlanID}`,
        method,
        body,
        token
    );
};
const RepeatClass = (method, body, token) => {
    return httpcall(`adminuser/RepeatScheduleClass`, method, body, token);
};
const GenerateLink = (method, body, token) => {
    return httpcall(`adminuser/CreatePaymentLink`, method, body, token);
};
const enrollCourseedit = (method, body, token, id) => {
    return httpcall(`adminuser/Update_EnrollCourse/${id}`, method, body, token);
};
const cancel_All_classes = (method, body, token) => {
    return httpcall(`adminuser/cancel_batch_classes`, method, body, token);
};
const complete_batch = (method, body, token) => {
    return httpcall(`adminuser/complete_batch`, method, body, token);
};
const GetTutorLeave = (method, body, token) => {
    return httpcall(`adminuser/Get_Tutor_Leaves`, method, body, token);
};
const GetResources = (method, body, token) => {
    return httpcall(`adminuser/resourse_list`, method, body, token);
};
const addResources = (method, body, token) => {
    return httpcall(`adminuser/add_resource`, method, body, token);
};
const getResourceDetails = (method, body, token, ResourceID) => {
    return httpcall(
        `adminuser/resource_detail/${ResourceID}`,
        method,
        body,
        token
    );
};
const updateResource = (method, body, token, ResourceID) => {
    return httpcall(
        `adminuser/update_resource/${ResourceID}`,
        method,
        body,
        token
    );
};
const ResourceSearch = (method, body, token, ResourceID) => {
    return httpcall(`adminuser/search_resource`, method, body, token);
};
//----------------Logs---------
const ActivityLogs = (method, body, token, id, type) => {
    return httpcall(
        `adminuser/Get_ActivityLogs?ActionFor=${id}&ActionForType=${type}`,
        method,
        body,
        token
    );
};
const List_tutoravailability = (method, body, token, id, type) => {
    return httpcall(`adminuser/List_tutoravailability`, method, body, token);
};
const bulk_cancel = (method, body, token, id, type) => {
    return httpcall(`adminuser/bulk_cancel`, method, body, token);
};
const Generate_manual_PaymentLink = (method, body, token, id, type) => {
    return httpcall(`adminuser/generate_manual_payment`, method, body, token);
};
const manual_PaymentLink = (method, body, token) => {
    return httpcall(
        `adminuser/parent_manual_paymentlinks`,
        method,
        body,
        token
    );
};
const manual_Adjustment = (method, body, token, type) => {
    return httpcall(`adminuser/add_deduct_wallet`, method, body, token);
};
const delete_resource_message = (method, body, token) => {
    return httpcall(`adminuser/resource_linked_Lessons`, method, body, token);
};
const delete_resource = (method, body, token, id) => {
    return httpcall(`adminuser/delete_resource/${id}`, method, body, token);
};
const get_config = (method, body, token) => {
    return httpcall(`adminuser/get_config`, method, body, token);
};
const add_config = (method, body, token) => {
    return httpcall(`adminuser/add_config`, method, body, token);
};
const update_config = (method, body, token, id) => {
    return httpcall(`adminuser/update_config/${id}`, method, body, token);
};
const get_Notification_count = (method, body, token) => {
    return httpcall(
        `adminuser/get_unseen_noticationcount`,
        method,
        body,
        token
    );
};
const Reset_Notification_count = (method, body, token) => {
    return httpcall(
        `adminuser/update_notification_lastseen`,
        method,
        body,
        token
    );
};
const Student_Registration_Dropdown = (method, body, token) => {
    return httpcall(`adminuser/students_dropdown`, method, body, token);
};
const Student_Registration_ADD = (method, body, token) => {
    return httpcall(`parent/enroll_course_web`, method, body, token);
};
const GetDahboard_Cancelled_Classes = (method, body, token, queryParams) => {
    const urlWithQuery = queryParams
        ? `adminuser/cancelclasscount_byuser?${new URLSearchParams(
              queryParams
          )}`
        : "adminuser/cancelclasscount_byuser";
    return httpcall(urlWithQuery, method, body, token);
};
const Delete_Lesson_plan = (method, body, token, id) => {
    return httpcall(`adminuser/delete_lessonplan/${id}`, method, body, token);
};
const Get_Tutor_Earnings = (method, body, token) => {
    return httpcall(`adminuser/get_tutor_earnings`, method, body, token);
};
const Create_Payouts = (method, body, token) => {
    return httpcall(`adminuser/create_payouts`, method, body, token);
};
const Get_Payout = (method, body, token) => {
    return httpcall(`adminuser/get_payouts`, method, body, token);
};
const PayoutDetails = (method, body, token) => {
    return httpcall(`adminuser/get_payout_detail`, method, body, token);
};
const update_payouts = (method, body, token, id) => {
    return httpcall(`adminuser/update_payouts/${id}`, method, body, token);
};
const updateComplaint = (method, body, token, id) => {
    return httpcall(`adminuser/update_complaint/${id}`, method, body, token);
};
const MarkAttendace = (method, body, token) => {
    return httpcall(`adminuser/mark_studentattendance`, method, body, token);
};
const ReportSubmitStudent = (method, body, token) => {
    return httpcall(`adminuser/submit_report`, method, body, token);
};
const get_Lesson_Plan = (method, body, token) => {
    return httpcall(`adminuser/get_pending_lessonplans`, method, body, token);
};
const Complete_Class = (method, body, token) => {
    return httpcall(`adminuser/complete_schedule_class`, method, body, token);
};
const getAllTutorReviews = (method, body, token) => {
    return httpcall(`adminuser/get_tutor_ratings_reviews`, method, body, token);
};
const ReverseTutorPenalty = (method, body, token) => {
    return httpcall(`adminuser/reverse_tutorpenality`, method, body, token);
};
const AllReportsDownload = (method, body, token) => {
    return httpcall(`adminuser/allreports`, method, body, token);
};
const getBatchByParent = (method, body, token) => {
    return httpcall(`adminuser/get_parent_batches`, method, body, token);
};
const getBatchByTutor = (method, body, token) => {
    return httpcall(`adminuser/get_tutor_batches`, method, body, token);
};
const getCommunications = (method, body, token) => {
    return httpcall(`adminuser/communications`, method, body, token);
};
const updateClassDuration = (method, body, token) => {
    return httpcall(`adminuser/update_classduration`, method, body, token);
};
const updateTutor = (method, body, token) => {
    return httpcall(`adminuser/change_tutor`, method, body, token);
};
const bulkpayouts = (method, body, token) => {
    return httpcall(`adminuser/bulk_payouts`, method, body, token);
};
const getbulkpayouts = (method, body, token) => {
    return httpcall(`adminuser/get_draft_payouts`, method, body, token);
};
const updatePayoutIDs = (method, body, token) => {
    return httpcall(`adminuser/update_draft_payouts`, method, body, token);
};
const AddDemoRequest = (method, body, token) => {
    return httpcall(`adminuser/admin_request_demo`, method, body, token);
};
const getDemoRequetsSearch = (method, body, token, courseId, mobile) => {
    return httpcall(
        `adminuser/get_demo_students?MobileNumber=${mobile}&CourseID=${courseId}`,
        method,
        body,
        token
    );
};
const CreateDemoClasses = (method, body, token, courseId, mobile) => {
    return httpcall(`adminuser/create_democlass`, method, body, token);
};
const getDemoClasses = (method, body, token, skip, status) => {
    return httpcall(`adminuser/get_demo_classes`, method, body, token);
};
const getDemoDetails = (method, body, token, demo_Id) => {
    return httpcall(
        `adminuser/democlass_details?DemoClassID=${demo_Id}`,
        method,
        body,
        token
    );
};
const UPdateDemoClass = (method, body, token, DemoClassID) => {
    return httpcall(
        `adminuser/update_democlass/${DemoClassID}`,
        method,
        body,
        token
    );
};
const CompleteClasses_demo = (method, body, token) => {
    return httpcall(`adminuser/complete_democlass`, method, body, token);
};
const CancelDemoClass = (method, body, token, Id) => {
    return httpcall(`adminuser/cancel_demo_class/${Id}`, method, body, token);
};
const AddStudentDemo = (method, body, token) => {
    return httpcall(`adminuser/Add_Student_To_Demo`, method, body, token);
};
const list_salesmanger = (method, body, token) => {
    return httpcall(`adminuser/list_salesmanger`, method, body, token);
};
const updateDemoRequest = (method, body, token, id) => {
    return httpcall(`adminuser/update_demorequest/${id}`, method, body, token);
};
const getDemoReports = (method, body, token) => {
    return httpcall(`adminuser/demo_reports`, method, body, token);
};
const exportDemoRequests = (method, body, token) => {
    return httpcall(`adminuser/export_demo_reports`, method, body, token);
};
const listAllAdmin = (method, body, token) => {
    return httpcall(`adminuser/list_admin_users`, method, body, token);
};
const tutor_cancel_reports = (method, body, token) => {
    return httpcall(`adminuser/tutor_cancel_reports`, method, body, token);
};
const cancelledclasses_by_type = (method, body, token) => {
    return httpcall(`adminuser/cancelledclasses_by_type`, method, body, token);
};
export const Services = {
    AddStudentDemo,
    tutor_cancel_reports,
    cancelledclasses_by_type,
    updateDemoRequest,
    listAllAdmin,
    exportDemoRequests,
    getDemoReports,
    getCommunications,
    list_salesmanger,
    CancelDemoClass,
    CompleteClasses_demo,
    Login,
    UPdateDemoClass,
    getDemoClasses,
    AddDemoRequest,
    getDemoDetails,
    getbulkpayouts,
    updateTutor,
    getDemoRequetsSearch,
    bulkpayouts,
    Complete_Class,
    GetAdmin,
    getBatchByTutor,
    ReverseTutorPenalty,
    get_config,
    updatePayoutIDs,
    AllReportsDownload,
    get_Lesson_Plan,
    ReportSubmitStudent,
    CreateDemoClasses,
    getAllTutorReviews,
    updateClassDuration,
    updateComplaint,
    Get_Tutor_Earnings,
    Create_Payouts,
    Get_Payout,
    addAdmin,
    GetTutors,
    MarkAttendace,
    delete_resource,
    update_payouts,
    bulk_cancel,
    DeleteParent,
    getAdminDetailsByID,
    UpdateAdmin,
    Student_Registration_Dropdown,
    GetDahboard_Cancelled_Classes,
    AddTutor,
    delete_resource_message,
    GetTutorDetails,
    Reset_Notification_count,
    GetStudents,
    getStudentDetailsByID,
    Generate_manual_PaymentLink,
    ListAllParents,
    Delete_Lesson_plan,
    AddStudent,
    paymentStatus,
    listAllStudents,
    getParentDetailsByID,
    UpdateTutorById,
    AddParent,
    UpdateParent,
    manual_Adjustment,
    ListAllParents_Dropdown,
    updateStudent,
    getCategories,
    Student_Registration_ADD,
    getParentDetailsByNumber,
    getCategoriesdetailsById,
    addCategories,
    updateCategories,
    assignDemo,
    getCourses,
    allCourses,
    getcourseDetailById,
    updateCourses,
    getLessons,
    addLessons,
    lessonGetById,
    Updatelesson,
    deletecourseQuestion,
    Enrolled_List,
    Demo_Request,
    Get_Classes,
    searchParentByMobileNumber,
    getbatchDetails,
    update_batchDuration,
    create_batch,
    getScheduledClasses,
    getScheduledClassesDetails,
    classDetailsByBatchId,
    createClasses,
    enquiry_List,
    Contact_us,
    Complaints,
    updateBatchDetails,
    addStudentToBatch,
    deleteStudent,
    CancelClass,
    removeDemoStudent,
    getAdminroles,
    addrole,
    updaterole,
    DeleteRole,
    Notifications,
    NotificationsByscheduleClassId,
    Reschedule,
    Approvals,
    ApprovalCancelrequest,
    updateEnrolledCourse,
    manual_PaymentLink,
    get_all_scheduledClasses,
    searchActiveTutor,
    getLinkedTutorByAdminId,
    searchActiveStudent,
    Claim_Enroll_Course,
    enrolled_List_Filter,
    enrolled_List_Details,
    Batch_List_Filter,
    Move_from_Draft_Batch,
    get_Course_Lessons,
    add_Lesson_plan,
    GET_lessonplanDetails,
    updateLesson,
    RepeatClass,
    parentWallet,
    GenerateLink,
    enrollCourseedit,
    cancel_All_classes,
    complete_batch,
    GetTutorLeave,
    GetResources,
    addResources,
    getResourceDetails,
    updateResource,
    ActivityLogs,
    List_tutoravailability,
    ResourceSearch,
    add_config,
    update_config,
    get_Notification_count,
    PayoutDetails,
    getBatchByParent,
    Claim_Enroll_Course_demo,
    Demo_Request_Search,
};
