import React, { useState } from "react";
import detailsClass from "./index.module.css";
import { FaUser } from "react-icons/fa";
import { AiFillThunderbolt, AiFillStar } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { IoIosBook } from "react-icons/io";
import { useSearchParams } from "react-router-dom";
import { FaMoneyBillAlt } from "react-icons/fa";
import { MdFreeCancellation } from "react-icons/md";

// import { PiPaypalLogoLight } from "react-icons/pi";
function Tabs({ isActive, setLoading, setIsActive }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [admindata, setadmindata] = useState(
        JSON.parse(localStorage.getItem("admindata")) || { Type: 1 }
    );
    const tabHandler = (e) => {
        const btnType = e.currentTarget.getAttribute("btn-type");
        // console.log();
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            tab: btnType,
        });
        // setIsActive(btnType);
        setLoading(true);
    };
    return (
        <div className={detailsClass["control-btns"]}>
            <button
                className={`${detailsClass["button"]} ${
                    isActive == "Profile" ? detailsClass["btn-active"] : ""
                }`}
                btn-type="Profile"
                onClick={tabHandler}>
                <FaUser /> Profile
            </button>

            <button
                className={`${detailsClass["button"]} ${
                    isActive == "Activity" ? detailsClass["btn-active"] : ""
                }`}
                btn-type="Activity"
                onClick={tabHandler}>
                <AiFillThunderbolt />
                Activity
            </button>

            {admindata.Type != 14 && (
                <button
                    className={`${detailsClass["button"]} ${
                        isActive == "Email" ? detailsClass["btn-active"] : ""
                    }`}
                    btn-type="Email"
                    onClick={tabHandler}>
                    <MdOutlineEmail />
                    Communications
                </button>
            )}
            <button
                className={`${detailsClass["button"]} ${
                    isActive == "Review" ? detailsClass["btn-active"] : ""
                }`}
                btn-type="Review"
                onClick={tabHandler}>
                <AiFillStar />
                Reviews
            </button>
            <button
                className={`${detailsClass["button"]} ${
                    isActive == "Courses" ? detailsClass["btn-active"] : ""
                }`}
                btn-type="Courses"
                onClick={tabHandler}>
                <IoIosBook />
                Courses
            </button>
            {admindata.Type != 14 && (
                <button
                    className={`${detailsClass["button"]} ${
                        isActive == "Earnings" ? detailsClass["btn-active"] : ""
                    }`}
                    btn-type="Earnings"
                    onClick={tabHandler}>
                    <FaMoneyBillAlt />
                    Outstanding
                </button>
            )}
            {admindata?.Type != 14 && (
                <button
                    className={`${detailsClass["button"]} ${
                        isActive == "Payouts" ? detailsClass["btn-active"] : ""
                    }`}
                    btn-type="Payouts"
                    onClick={tabHandler}>
                    {/* <PiPaypalLogoLight /> */}
                    Payouts
                </button>
            )}
            <button
                className={`${detailsClass["button"]} ${
                    isActive == "Batches" ? detailsClass["btn-active"] : ""
                }`}
                btn-type="Batches"
                onClick={tabHandler}>
                {/* <PiPaypalLogoLight /> */}
                Batches
            </button>
            <button
                className={`${detailsClass["button"]} ${
                    isActive == "CancellationReport"
                        ? detailsClass["btn-active"]
                        : ""
                }`}
                btn-type="CancellationReport"
                onClick={tabHandler}>
                <MdFreeCancellation />
                Cancellation Report
            </button>
        </div>
    );
}

export default Tabs;
