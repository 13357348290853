import React, { useState } from "react";
import TutorClasses from "./Tutor.module.css";

function Tabs({
    setSearchParams,
    setActive,
    setLoading,
    getAllTutors,
    setCurrentPage,
    Active,
}) {
    const [admindata, setadmindata] = useState(
        JSON.parse(localStorage.getItem("admindata")) || { Type: 1 }
    );
    const NavHandler = (e) => {
        let Type;
        if (admindata?.Type != 14) {
            Type = e.currentTarget.getAttribute("btn-type");
        } else {
            Type = 1;
        }
        setSearchParams({ tab: Type, page: 1 });
        setActive(Type);
        setLoading(true);
        getAllTutors(Type, 0);
        setCurrentPage(0);
    };
    return (
        <div className={TutorClasses["Navigator"]}>
            {admindata?.Type != 14 && (
                <button
                    btn-type="2"
                    className={Active == "2" ? TutorClasses["active"] : ""}
                    onClick={NavHandler}>
                    Pending
                </button>
            )}
            <button
                btn-type="1"
                className={Active == "1" ? TutorClasses["active"] : ""}
                onClick={NavHandler}>
                Approved
            </button>
            {admindata?.Type != 14 && (
                <button
                    btn-type="3"
                    className={Active == "3" ? TutorClasses["active"] : ""}
                    onClick={NavHandler}>
                    Rejected
                </button>
            )}
            {admindata?.Type != 14 && (
                <button
                    btn-type="4"
                    className={Active == "4" ? TutorClasses["active"] : ""}
                    onClick={NavHandler}>
                    Dormant
                </button>
            )}
        </div>
    );
}

export default Tabs;
